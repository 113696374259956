import style from "./story.module.scss";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { useParams } from "react-router-dom";
import { OwnStory } from "../../components/SectionStories/componentsSectionStories/OwnStory";
import { EmptyStory } from "../../components/SectionStories/componentsSectionStories/EmptyStory";

export function Story() {
	const { storyId } = useParams();
	const storiesData = useNestedObjectTranslation(uk, "stories", "people");

	// Пошук історії з вказаним storyId в масиві storiesData
	const selectedPerson = storiesData
		.map((currentPerson) => {
			// Отримати ключ (наприклад, "person1")
			const personKey = Object.keys(currentPerson)[0];

			// Отримати основний об'єкт персони
			return currentPerson[personKey];
		})
		.find((person) => person.id === storyId);

	// Перевірка, чи існує обрана історія та чи вона має ключ ownStory, який не порожній
	const hasOwnStory =
		selectedPerson &&
		selectedPerson.ownStory &&
		Object.keys(selectedPerson.ownStory).length > 0;

	return (
		<div id="main">
			<section className={style.wrapperStory}>
				<div className={style.container}>
					{selectedPerson && hasOwnStory ? (
						<OwnStory story={selectedPerson.ownStory} />
					) : (
						<EmptyStory />
					)}
				</div>
			</section>
		</div>
	);
}
