import { motion } from "framer-motion";
import style from "./burgerMenu.module.scss";
import { Close, Logo } from "../../../Icons";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Button";
import { Menu } from "../Menu";
import { Language } from "../Language";
import { wrapperBurger, containerBurger } from "../../../../utils/animation";
import { Link } from "react-router-dom";

export function BurgerMenu({ isOpenBurgerMenu, toggleBurgerMenu, isMobile }) {
	const { t } = useTranslation();

	return (
		<motion.div
			className={style.wrapper}
			{...wrapperBurger}
			style={{
				clipPath: "inset(0% 95% 95% 0% round 10px)",
			}}
		>
			<motion.div
				className={style.container}
				{...containerBurger}
			>
				<div className={style.header}>
					<Link
						to="/"
						className={style.logo}
						onClick={toggleBurgerMenu}
					>
						<Logo />
					</Link>
					<h2 className={style.title}>{t("header.burger.title")}</h2>
					<Button
						btnType={"button"}
						classBtn={style.burger_btn}
						text={<Close />}
						btnClick={toggleBurgerMenu}
					/>
				</div>
				<Menu
					isOpenBurgerMenu={isOpenBurgerMenu}
					toggleBurgerMenu={toggleBurgerMenu}
					isMobile={isMobile}
				/>
				<div className={style.lang}>
					<div className={style.lang_title}>{t("header.burger.lang")}</div>
					<div className={style.lang_box}>
						<Language
							isMobile={isMobile}
							toggleBurgerMenu={toggleBurgerMenu}
						/>
					</div>
				</div>
				<div
					className={style.contact}
					onClick={toggleBurgerMenu}
				>
					{t("header.menu.contacts.title")}
				</div>
				<Button
					classBtn={style.support_btn}
					text={t("header.btn")}
					btnType={"button"}
					btnClick={toggleBurgerMenu}
				/>
			</motion.div>
		</motion.div>
	);
}
