import { useTranslation } from "react-i18next";
import style from "./sectionTeam.module.scss";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { Member } from "./componentsSectionTeam/Member";
import { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { Modal } from "../Modal";
import { AnimatePresence } from "framer-motion";
import { SliderTeam } from "./componentsSectionTeam/SliderTeam";

export function SectionTeam({ isMobile }) {
	const { t } = useTranslation();
	const teamData = useNestedObjectTranslation(uk, "team", "members");
	const { isModal, open, close } = useContext(ModalContext);
	const [selectedMember, setSelectedMember] = useState(null);

	const handleMemberOpen = (member) => {
		setSelectedMember(member);
		open();
	};

	const handleMemberClose = () => {
		setSelectedMember(null);
		close();
	};

	return (
		<section className={style.wrapperTeam}>
			<div className={style.container}>
				<div className={style.box_title}>
					<h3 className={style.title}>{t("team.title")}</h3>
					<h4 className={style.subtitle}>{t("team.subtitle")}</h4>
					<p className={style.desc}>{t("team.desc")}</p>
				</div>
				<div className={style.box_team}>
					{!isMobile ? (
						teamData.map((item, index) => {
							const memberKey = Object.keys(item)[0]; // Отримуємо ключ об'єкта
							const member = item[memberKey]; // Отримуємо значення об'єкта (дані члена команди)
							return (
								!isMobile && (
									<Member
										key={index}
										isMobile={isMobile}
										member={member}
										onOpen={() => handleMemberOpen(member)}
										isModal={isModal}
									/>
								)
							);
						})
					) : (
						<SliderTeam
							teamData={teamData}
							isModal={isModal}
							isMobile={isMobile}
						/>
					)}
				</div>

				<AnimatePresence>
					{isModal && !isMobile && (
						<Modal onClose={handleMemberClose}>
							<Member
								member={selectedMember}
								onClose={handleMemberClose}
								isModal={isModal}
								isMobile={isMobile}
								selectedMember={selectedMember}
							/>
						</Modal>
					)}
				</AnimatePresence>
			</div>
		</section>
	);
}
