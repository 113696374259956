import { useTranslation } from "react-i18next";
import style from "./news.module.scss";
import { NewsList } from "../../components/SectionNews/componentsSectionNews/NewsList";

export function News() {
	const { t } = useTranslation();

	return (
		<div id="main">
			<section className={style.wrapperNews}>
				<div className={style.container}>
					<h2 className={style.title}>{t("news.title")}</h2>
					<NewsList />
				</div>
			</section>
		</div>
	);
}
