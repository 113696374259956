export function Facebook({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="#3C5A99"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6464 20.0217V32.3042C16.6464 32.4807 16.7894 32.6243 16.9664 32.6243H21.5274C21.7039 32.6243 21.8474 32.4812 21.8474 32.3042V19.8217H25.1539C25.3204 19.8217 25.4589 19.6942 25.4729 19.5287L25.7909 15.7677C25.8069 15.5812 25.6594 15.4207 25.4719 15.4207H21.8474V12.7522C21.8474 12.1267 22.3544 11.6198 22.9799 11.6198H25.5284C25.7049 11.6198 25.8484 11.4767 25.8484 11.2997V7.53874C25.8484 7.36224 25.7054 7.21875 25.5284 7.21875H21.2219C18.6949 7.21875 16.6464 9.26725 16.6464 11.7943V15.4202H14.3659C14.1894 15.4202 14.0459 15.5632 14.0459 15.7402V19.5013C14.0459 19.6778 14.1889 19.8212 14.3659 19.8212H16.6464V20.0217Z"
				fill="white"
			/>
		</svg>
	);
}
