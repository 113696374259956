import style from "./person.module.scss";
import { DoubleQuotes } from "../../../Icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Person({ person }) {
	const { t } = useTranslation();

	return (
		<div className={style.person_body}>
			<div className={style.image}>
				<img
					src={person.url}
					alt={person.name}
				/>
			</div>
			<div className={style.person_content}>
				<span className={style.quotes}>
					<DoubleQuotes />
				</span>
				<div className={style.desc_body}>
					<p className={style.person_desc}>{person.desc1}</p>
					{person.desc2 && <p className={style.person_desc}>{person.desc2}</p>}
				</div>
				<div className={style.person_name}>{person.name}</div>
				<Link
					to={`stories/${person.id}`}
					className={style.btn_read}
				>
					<span>{t("stories.btn")}</span>
				</Link>
			</div>
		</div>
	);
}
