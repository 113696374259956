export function Burger({ mainWidth = 40, mainHeight = 40 }) {
	return (
		<svg
			width={mainWidth}
			height={mainHeight}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="24"
				height="4"
				transform="translate(8 10)"
				fill="#03071E"
			/>
			<rect
				width="20"
				height="4"
				transform="translate(8 18)"
				fill="#03071E"
			/>
			<rect
				width="16"
				height="4"
				transform="translate(8 26)"
				fill="#03071E"
			/>
		</svg>
	);
}
