import style from "./newsList.module.scss";
import { NewsDetails } from "../NewsDetails";
import { usePagination } from "../../../../utils/hooks/usePagination";
import { uk } from "../../../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../../../utils/hooks/useNestedObjectTranslation";
import { ArrowSwiper } from "../../../Icons";
import { scrollTo } from "../../../../utils/helpers";
import { useMediaQuery } from "react-responsive";

export function NewsList() {
	const news = useNestedObjectTranslation(uk, "news", "allNews").map((item) => {
		return item[Object.keys(item)[0]];
	});

	const isMobile = useMediaQuery({ maxWidth: 991 });

	const {
		firstContentIndex,
		lastContentIndex,
		nextPage,
		prevPage,
		page,
		setPage,
		totalPages,
		pageNumbers,
	} = usePagination({
		contentPerPage: 5,
		count: news?.length,
	});

	function renderPageNumbers() {
		const ellipsis = "...";

		if (totalPages) {
			const visiblePages =
				totalPages <= 6
					? pageNumbers
					: page <= 4
					? pageNumbers.slice(0, 5)
					: page >= totalPages - 4
					? pageNumbers.slice(totalPages - 4)
					: pageNumbers.slice(page - 3, page + 2);

			return (
				<>
					{page >= 4 && totalPages > 5 && (
						<>
							<button
								onClick={() => setPage(1)}
								className={`${style.pagination__num} ${
									page === 1 && style.pagination__num_active
								}`}
							>
								1
							</button>
							<span className="ellipsis">{ellipsis}</span>
						</>
					)}

					{visiblePages.map(
						(number) =>
							(number > 2 || page <= 3 || totalPages <= 5) && (
								<button
									onClick={() => {
										setPage(number);
										scrollTo("#news");
									}}
									key={number}
									className={`${style.pagination__num} ${
										page === number && style.pagination__num_active
									}`}
								>
									{number}
								</button>
							)
					)}

					{page < totalPages - 2 && totalPages > 5 && (
						<>
							<span className="ellipsis">{ellipsis}</span>
							<button
								onClick={() => setPage(totalPages)}
								className={`${style.pagination__num} ${
									page === totalPages && style.pagination__num_active
								}`}
							>
								{totalPages}
							</button>
						</>
					)}
				</>
			);
		}

		return null;
	}

	return (
		<div
			id="news"
			className={style.news}
		>
			<div className={style.news_wrapper}>
				{news?.slice(firstContentIndex, lastContentIndex).map((article) => (
					<NewsDetails
						article={article}
						key={article.id}
						isMobile={isMobile}
					/>
				))}
			</div>
			{totalPages > 1 && (
				<div className={style.pagination}>
					{page === 1 ? null : (
						<button
							onClick={() => {
								prevPage();
								scrollTo("#news");
							}}
							className={style.arrow_left}
						>
							<ArrowSwiper />
						</button>
					)}
					{renderPageNumbers()}
					{totalPages === page ? null : (
						<button
							onClick={() => {
								nextPage();
								scrollTo("#news");
							}}
							className={style.arrow_right}
						>
							<ArrowSwiper />
						</button>
					)}
				</div>
			)}
		</div>
	);
}
