import style from "./modal.module.scss";
import { motion } from "framer-motion";
import {wrapperModal} from "../../utils/animation";

export function Modal({ children, onClose }) {
	return (
		<motion.div
			className={style.wrapperModal}
			onClick={onClose}
			style={{
				clipPath: "inset(0% 95% 95% 0% round 10px)",
			}}
			{...wrapperModal}
		>
			<div
				className={style.containerModal}
				onClick={(e) => e.stopPropagation()}
			>
				{children}
			</div>
		</motion.div>
	);
}
