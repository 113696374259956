import { useTranslation } from 'react-i18next';
import style from './sectionAdvantages.module.scss';
import { uk } from '../../i18n/locales/uk';
import { useNestedObjectTranslation } from '../../utils/hooks/useNestedObjectTranslation';


export function SectionAdvantages() {
	const{t} = useTranslation();
	const advantagesData = useNestedObjectTranslation(uk, "advantages", "info");

	return (
		<section className={style.wrapperAdvateges}>
			<div className={style.container}>
				<div className={style.box_title}>
					<h3 className={style.title}>{t("advantages.title")}</h3>
					<h4 className={style.subtitle}>{t("advantages.subtitle")}</h4>
					<p className={style.desc}>{t("advantages.desc")}</p>
				</div>
				<div className={style.box_advateges}>
					{advantagesData.map((column, columnIndex) => (
						<div
							className={style.column}
							key={columnIndex}
						>
							{Object.keys(column).map((key) => {
								const { url, title, desc } = column[key];
								return (
									<div
										className={style.item}
										key={key}
									>
										<div className={style.item_img}>
											<img
												src={url}
												alt="Advantage"
											/>
										</div>
										<h3 className={style.item_title}>{title}</h3>
										<p className={style.item_desc}>{desc}</p>
									</div>
								);
							})}
						</div>
					))}
				</div>
			</div>
		</section>
	);
}