import { useTranslation } from "react-i18next";
import style from "./pagePerson.module.scss";
import { Link } from "react-router-dom";

export function PagePerson({ person, isMobile }) {
	const { t } = useTranslation();

	return (
		<>
			{!isMobile ? (
				<Link
					to={`/stories/${person.id}`}
					className={style.person_link}
				>
					<div className={style.person_body}>
						<div className={style.image}>
							<img
								src={person.url}
								alt={person.name}
							/>
						</div>
						<div className={style.info_box}>
							<h3 className={style.info_name}>{person.name}</h3>
							<h4 className={style.info_position}>{person.win}</h4>
						</div>
					</div>
				</Link>
			) : (
				<div className={style.personMob_body}>
					<h4 className={style.title_mob}>{person.winMob}</h4>
					<div className={style.image_mob}>
						<img
							src={person.url}
							alt={person.name}
						/>
					</div>
					<Link
						to={`/stories/${person.id}`}
						className={style.btn_read}
					>
						<span>{t("stories.btn")}</span>
					</Link>
				</div>
			)}
		</>
	);
}
