export function UKFlag({ mainWidth = 20, mainHeight = 20 }) {
	return (
		<svg
			width={mainWidth}
			height={mainHeight}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_212_634"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="21"
				height="20"
			>
				<path
					d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_212_634)">
				<path
					d="M25.5 0H-4.5V20H25.5V0Z"
					fill="#002173"
				/>
				<mask
					id="mask1_212_634"
					style={{ maskType: "luminance" }}
					maskUnits="userSpaceOnUse"
					x="-5"
					y="0"
					width="31"
					height="20"
				>
					<path
						d="M25.5 0H-4.5V20H25.5V0Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask1_212_634)">
					<path
						d="M29.0573 17.5931L-6.71875 -0.294922L-8.06035 2.38828L27.7157 20.2763L29.0573 17.5931Z"
						fill="white"
					/>
					<path
						d="M-7.39753 1.0586L-7.84473 1.953L10.0433 10.897L10.4905 10.0026L-7.39753 1.0586Z"
						fill="#C61018"
					/>
					<path
						d="M10.9384 9.10853L10.4912 10.0029L28.3792 18.9469L28.8264 18.0525L10.9384 9.10853Z"
						fill="#C61018"
					/>
					<path
						d="M27.7096 -0.290344L-8.06641 17.5977L-6.72481 20.2809L29.0512 2.39286L27.7096 -0.290344Z"
						fill="white"
					/>
					<path
						d="M10.4925 10.0003L-7.39551 18.9443L-6.94831 19.8387L10.9397 10.8947L10.4925 10.0003Z"
						fill="#C61018"
					/>
					<path
						d="M27.9339 0.162445L10.0459 9.10645L10.4931 10.0008L28.3811 1.05685L27.9339 0.162445Z"
						fill="#C61018"
					/>
					<path
						d="M28.001 7.50098H13.001V0.000976562H8.00098V7.50098H-6.99902V12.501H8.00098V20.001H13.001V12.501H28.001V7.50098Z"
						fill="white"
					/>
					<path
						d="M28.001 8.50098H12.001V0.000976562H9.00098V8.50098H-6.99902V11.501H9.00098V20.001H12.001V11.501H28.001V8.50098Z"
						fill="#C61018"
					/>
				</g>
			</g>
		</svg>
	);
}
