import { Route, Routes } from "react-router-dom";
import { MainLayout } from "../pages/MainLayout";
import { Home } from "../pages/Home";
import { Stories } from "../pages/Stories";
import { Story } from "../pages/Story";
import { News } from "../pages/News";
import { NewsArticle } from "../pages/NewsArticle";

export default function Router() {
	return (
		<Routes>
			<Route
				path="/"
				element={<MainLayout />}
			>
				<Route
					index
					element={<Home />}
				/>
				<Route
					path="/stories"
					element={<Stories />}
				/>
				<Route
					path="/stories/:storyId"
					element={<Story />}
				/>
				<Route
					path="/news"
					element={<News />}
				/>
				<Route
					path="/news/:newsId"
					element={<NewsArticle />}
				/>
			</Route>
		</Routes>
	);
}
