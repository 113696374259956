import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Navigation, Thumbs, FreeMode } from "swiper/modules";
import style from "./sliderTeam.module.scss";
import { Member } from "../Member";
import { ArrowSwiper } from "../../../Icons";

export function SliderTeam({ teamData, isModal, isMobile }) {
	return (
		<Swiper
			modules={[Navigation, Thumbs, FreeMode]}
			loop={true}
			spaceBetween={0}
			navigation={{
				prevEl: `.${style.prevBtn}`,
				nextEl: `.${style.nextBtn}`,
			}}
		>
			<div
				slot="container-end"
				className={style.btn_container}
			>
				<div className={style.prevBtn}>
					<ArrowSwiper />
				</div>
				<div className={style.nextBtn}>
					<ArrowSwiper />
				</div>
			</div>
			{teamData.map((item, index) => (
				<SwiperSlide key={index}>
					<Member
						key={index}
						isMobile={isMobile}
						member={item[Object.keys(item)[0]]}
						isModal={isModal}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
