import style from "./article.module.scss";
import { Link } from "react-router-dom";

export function Article({ news }) {
	return (
		<Link to={`/news/${news.id}`} className={style.link_article}>
			<div className={style.box_article}>
				<div className={style.image}>
					<img
						src={news.url}
						alt={news.title}
					/>
				</div>
				<div className={style.content_article}>
					<div className={style.title_box}>
						<div className={style.date}>{news.date}</div>
						<div className={style.author}>
							<span>{news.author}</span>
							<span>{news.authorName}</span>
						</div>
					</div>
					<h3 className={style.title}>{news.title}</h3>
					<div className={style.desc_body}>
						<p className={style.text}>{news.text}</p>
						{news.li1 && (
							<ul className={style.list}>
								<li className={style.item}>{news.li1}</li>
								<li className={style.item}>{news.li2}</li>
							</ul>
						)}
						{news.text2 && <p className={style.text}>{news.text2}</p>}
						{news.text3 && <p className={style.text}>{news.text3}</p>}
						{news.text4 && <p className={style.text}>{news.text4}</p>}
					</div>
				</div>
			</div>
		</Link>
	);
}
