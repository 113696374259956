import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import { SlidePartner } from "../SlidePartner";
import { partnersImg } from "../../../../utils/dataImages";
import style from "./sliderPartners.module.scss"

export function SliderPertners() {

	return (
		<Swiper
			modules={[Autoplay, FreeMode, Navigation]}
			loop={true}
			// slidesPerGroup={1}
			slidesPerView={3}
			// slidesPerView={"auto"}
			// centeredSlides={true}
			spaceBetween={20}
			speed={500}
			autoplay={{
				delay: 2000,
				disableOnInteraction: false,
			}}
		>
			{partnersImg.map((url, index) => (
				<SwiperSlide key={index} className={style.swiperSlide}>
					<SlidePartner
						key={index}
						url={url}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
