import { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { UKRFlag, UKFlag, DEUFlag, ArrowDown } from "../../../Icons";
import { LOCALS } from "../../../../i18n/constants";
import style from "./language.module.scss";
import i18next from "i18next";
import {
	wrapperAnimation,
	linkFlagAnimation,
} from "../../../../utils/animation";

export function Language({ isMobile, toggleBurgerMenu }) {
	const [isShowDropdown, setIsShowDropdown] = useState(false);
	const selectedRef = useRef(null);
	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		setIsShowDropdown((prevState) => !prevState);
	};

	const languages = [
		{ code: LOCALS.UK, name: "UKR", flag: <UKRFlag /> },
		{ code: LOCALS.EN, name: "ENG", flag: <UKFlag /> },
		{ code: LOCALS.DE, name: "DEU", flag: <DEUFlag /> },
	];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isShowDropdown &&
				selectedRef.current &&
				dropdownRef.current &&
				!selectedRef.current.contains(event.target) && // Перевірка, чи клік відбувся не на підменю
				!dropdownRef.current.contains(event.target) // Перевірка, чи клік відбувся не на пункт меню
			) {
				setIsShowDropdown(false); // Закриття підменю
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isShowDropdown]);

	return (
		<>
			{/* Desktop */}
			<>
				{!isMobile && (
					<div className={style.wrapper}>
						<div
							className={style.selected}
							onClick={toggleDropdown}
							ref={selectedRef}
						>
							<span>
								{languages.find((lang) => lang.code === i18next.language)?.flag}
							</span>
							<span className={style.lang}>
								{languages.find((lang) => lang.code === i18next.language)?.name}
							</span>
							<span className={`${style.arrow} ${isShowDropdown && style.up}`}>
								<ArrowDown />
							</span>
						</div>
						<AnimatePresence>
							{isShowDropdown && (
								<motion.ul
									className={style.dropdown}
									ref={dropdownRef}
									{...wrapperAnimation}
								>
									{languages.map(
										(lang, index) =>
											lang.code !== i18next.language && (
												<motion.li
													key={lang.code}
													className={style.dropdownItem}
													onClick={() => i18next.changeLanguage(lang.code)}
													{...linkFlagAnimation(index)}
												>
													<span>{lang.flag}</span>
													<span>{lang.name}</span>
												</motion.li>
											)
									)}
								</motion.ul>
							)}
						</AnimatePresence>
					</div>
				)}
			</>
			{/* Mobil */}
			<>
				{isMobile && (
					<>
						{languages.map((lang) => (
							<div
								key={lang.code}
								className={`${style.mobileLang} ${
									lang.code === i18next.language && style.active
								}`}
								onClick={() => {
									i18next.changeLanguage(lang.code);
									toggleBurgerMenu();
								}}
							>
								<span>{lang.flag}</span>
								<span>{lang.name}</span>
							</div>
						))}
					</>
				)}
			</>
		</>
	);
}
