export function Logo({ width = 80, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 80 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.0837 14.4385C29.8275 15.1413 33.9279 16.7734 33.9279 16.7734L30.7206 29.2799C30.7206 29.2799 30.7707 35.2152 30.5774 39.2368C30.4342 42.2159 30.1765 44.7655 29.2887 46.832C28.7128 48.1727 27.771 49.9989 25.9382 49.7305C24.599 49.5344 24.3918 46.993 24.3918 46.993L23.6472 38.8611C23.6472 38.8611 23.6472 36.2846 21.7285 36.1505C19.8098 36.0163 18.7789 38.8611 18.7789 38.8611C18.7789 38.8611 17.748 42.1622 17.748 45.007C17.748 46.3758 18.0057 49.4287 16.2302 49.8915C14.8556 50.2499 13.0693 48.7799 12.6792 45.9195C12.1924 42.3501 11.9919 40.1607 11.9919 36.7409C11.9919 32.6078 12.1924 31.4001 12.1924 26.4351C12.1924 23.2772 11.4192 21.4432 10.2737 19.2425C8.31933 15.4877 4.46036 10.4128 4.46036 10.4128C4.46036 10.4128 0.982968 6.17234 0.222066 3.6764C-0.121568 2.5492 -0.190326 1.45244 0.938 0.885234C2.37973 0.16048 4.86128 2.76391 4.86128 2.76391C4.86128 2.76391 8.97158 7.90126 12.6792 10.1444C14.8901 11.482 16.3668 12.3209 18.8648 13.0966C21.9575 14.0569 23.9409 13.6335 27.0837 14.4385Z"
				fill="#588CC4"
			/>
			<path
				d="M26.9116 5.79657C26.9116 8.61724 24.3019 11.5936 21.5418 11.5936C17.326 11.5936 16.8027 7.99996 16.8027 5.17929C17.4449 2.4193 18.7817 0.804688 21.5418 0.804688C24.7526 0.804688 26.9116 2.9759 26.9116 5.79657Z"
				fill="#588CC4"
			/>
			<path
				d="M21.7231 27.1754C20.9843 27.1754 20.3604 26.96 19.8514 26.5291C19.7036 26.96 19.367 27.1754 18.8417 27.1754C18.5297 27.1754 18.267 27.0908 18.0536 26.9215C17.8401 26.7522 17.7334 26.5368 17.7334 26.2752V17.6892C17.7334 17.443 17.8319 17.2353 18.0289 17.066C18.2424 16.8814 18.5133 16.7891 18.8417 16.7891C19.1536 16.7891 19.4081 16.8814 19.6051 17.066C19.8021 17.2353 19.9007 17.4507 19.9007 17.7123V20.7359C20.4589 20.2589 21.091 20.0204 21.797 20.0204C22.8806 20.0204 23.7426 20.3435 24.3829 20.9898C25.0397 21.6206 25.368 22.4823 25.368 23.5748C25.368 24.6673 25.0397 25.5444 24.3829 26.206C23.7262 26.8523 22.8396 27.1754 21.7231 27.1754ZM21.5507 25.3059C22.0597 25.3059 22.462 25.1443 22.7575 24.8212C23.0694 24.4826 23.2254 24.0672 23.2254 23.5748C23.2254 23.067 23.0694 22.6593 22.7575 22.3515C22.462 22.0438 22.0597 21.8899 21.5507 21.8899C21.0582 21.8899 20.6559 22.0438 20.344 22.3515C20.0484 22.6593 19.9007 23.067 19.9007 23.5748C19.9007 24.0672 20.0484 24.4826 20.344 24.8212C20.6559 25.1443 21.0582 25.3059 21.5507 25.3059Z"
				fill="black"
			/>
			<path
				d="M33.9277 15.9151C35.6505 13.6224 37.9083 12.1374 41.2875 11.7552C44.6666 11.373 46.5281 11.6479 48.4181 12.4798C50.6107 13.4449 53.0573 16.6934 53.0573 16.6934L50.7091 33.0378C50.7091 33.0378 49.6495 43.1289 48.8763 46.43C48.4677 48.1746 47.3013 50.3752 45.7548 49.9458C44.3311 49.5505 44.3803 47.2083 44.3803 47.2083C44.3803 47.2083 44.295 44.6186 44.4948 43.3705C44.6667 42.2969 44.6094 39.6668 44.6094 39.6668C44.6094 39.6668 44.3516 37.6003 42.662 37.6003C41.4917 37.6003 40.5429 38.4894 40.5429 39.5863C40.5429 42.2701 40.7147 47.5304 40.7147 47.5304C40.7147 47.5304 40.7305 49.6469 39.5979 49.8921C38.2564 50.1825 37.3184 48.7332 36.8201 47.5304C35.8751 45.2491 35.1305 39.7473 35.1305 39.7473C35.1305 39.7473 34.5578 34.5944 34.2428 31.4275C34.0337 29.326 33.9277 27.4018 33.4982 27.2945C32.4823 27.0406 31.0354 29.2536 29.6035 30.3808C28.8561 30.9692 27.828 31.2128 27.1121 30.5687C26.3962 29.9246 27.0262 28.4753 27.3126 27.5897C27.4122 27.2815 28.4279 24.4446 30.0617 21.6585C31.8086 18.6794 32.4959 17.8206 33.9277 15.9151Z"
				fill="#F0CC4F"
			/>
			<path
				d="M48.504 4.79136C48.504 7.43756 46.0089 9.76906 43.2488 9.76906C39.0329 9.76906 38.252 6.53252 38.252 3.88633C38.8941 1.29706 41.4443 0 44.2044 0C47.4152 0 48.504 2.14517 48.504 4.79136Z"
				fill="#F0CC4F"
			/>
			<path
				d="M42.5584 27.1765C41.8688 27.1765 41.2203 27.0303 40.6128 26.738C40.0217 26.4456 39.5456 26.0225 39.1844 25.4685C38.8232 24.9146 38.6426 24.253 38.6426 23.4836C38.659 22.4219 39.0284 21.5833 39.7508 20.9678C40.4733 20.3369 41.4091 20.0215 42.5584 20.0215C43.0017 20.0215 43.4368 20.0676 43.8637 20.16C44.2906 20.2523 44.66 20.4216 44.9719 20.6677C45.2182 20.8524 45.3414 21.0909 45.3414 21.3832C45.3414 21.6294 45.2429 21.8526 45.0458 22.0526C44.8652 22.2526 44.6354 22.3526 44.3563 22.3526C44.1756 22.3526 44.0115 22.3065 43.8637 22.2142C43.6174 22.0911 43.3958 22.0064 43.1987 21.9603C43.0181 21.9141 42.8129 21.891 42.583 21.891C42.0412 21.891 41.5979 22.0372 41.2531 22.3296C40.9083 22.6219 40.7359 23.0143 40.7359 23.5067C40.7359 24.076 40.9248 24.5222 41.3024 24.8454C41.68 25.1531 42.1233 25.307 42.6323 25.307C43.2069 25.307 43.7488 25.1069 44.2577 24.7069C44.4548 24.5684 44.6436 24.4991 44.8242 24.4991C45.0869 24.4991 45.3085 24.5992 45.4891 24.7992C45.6862 24.9992 45.7847 25.23 45.7847 25.4916C45.7847 25.5993 45.76 25.7224 45.7108 25.8609C45.6615 25.984 45.5712 26.1071 45.4399 26.2302C44.9966 26.5687 44.5204 26.8072 44.0115 26.9457C43.5189 27.0996 43.0346 27.1765 42.5584 27.1765Z"
				fill="black"
			/>
			<path
				d="M54.5183 15.0559C50.6263 18.2589 46.9867 26.65 46.9867 26.65C46.9867 26.65 45.4306 30.0375 46.6717 30.7831C47.7886 31.454 49.2121 30.1716 50.1368 29.5217C50.4804 29.2801 52.5374 27.0349 52.9146 27.4015C53.6878 28.1529 53.7737 32.8228 53.9742 34.6478C54.4424 38.9105 55.091 42.8602 55.6924 44.7926C56.3776 46.9944 57.3238 50.7757 59.587 49.7576C61.0189 49.1135 60.5429 45.1791 60.4462 43.8801C60.2743 41.572 60.303 41.0621 60.303 39.5591C60.303 38.2441 61.2506 37.5999 62.4794 37.5999C64.0258 37.5999 64.6558 38.8402 64.6558 40.2301C64.6558 42.2966 64.2835 44.7389 64.169 46.9396C64.0893 48.4706 64.4509 49.6611 65.8872 49.9455C67.9204 50.348 68.6557 47.502 69.1518 45.4098C69.7819 42.7529 70.4978 36.4727 70.8414 33.0106C71.0589 30.8193 71.2324 27.8222 73.0751 27.6699C74.0488 27.5893 74.8919 29.2801 75.5952 30.3268C76.2984 31.3735 77.7143 33.5549 79.2607 33.0912C80.6926 32.6617 79.6044 29.9242 79.6044 29.9242C79.6044 29.9242 78.3694 25.1782 76.8838 22.4096C74.4998 17.9664 70.9399 13.471 67.6341 12.4258C65.2572 11.6744 62.4507 11.7091 61.1048 11.9159C57.9071 12.4071 56.9531 13.0521 54.5183 15.0559Z"
				fill="#CE5349"
			/>
			<path
				d="M69.3523 5.36765C69.3523 8.30245 67.0573 10.6816 64.2263 10.6816C59.9021 10.6816 59.043 7.12158 59.043 4.18677C59.7016 1.3151 62.1112 0.0537109 64.9422 0.0537109C68.2355 0.0537109 69.3523 2.43284 69.3523 5.36765Z"
				fill="#CE5349"
			/>
			<path
				d="M62.7563 27.1764C61.6562 27.1764 60.7696 26.8532 60.0964 26.207C59.4397 25.5453 59.1113 24.6683 59.1113 23.5758C59.1113 22.4833 59.4315 21.6216 60.0718 20.9907C60.7286 20.3445 61.5987 20.0213 62.6824 20.0213C63.3884 20.0213 64.0205 20.2598 64.5787 20.7368V17.7133C64.5787 17.4517 64.6772 17.2363 64.8743 17.067C65.0713 16.8824 65.3258 16.79 65.6377 16.79C65.9661 16.79 66.2288 16.8824 66.4258 17.067C66.6393 17.2363 66.746 17.444 66.746 17.6902V26.2762C66.746 26.5378 66.6393 26.7532 66.4258 26.9225C66.2288 27.0917 65.9661 27.1764 65.6377 27.1764C65.1123 27.1764 64.7757 26.961 64.628 26.5301C64.119 26.961 63.4951 27.1764 62.7563 27.1764ZM62.9287 25.3068C63.4212 25.3068 63.8153 25.1453 64.1108 24.8221C64.4227 24.4836 64.5787 24.0682 64.5787 23.5758C64.5787 23.068 64.4227 22.6602 64.1108 22.3525C63.8153 22.0448 63.4212 21.8909 62.9287 21.8909C62.4197 21.8909 62.0092 22.0448 61.6973 22.3525C61.4017 22.6602 61.254 23.068 61.254 23.5758C61.254 24.0682 61.4017 24.4836 61.6973 24.8221C62.0092 25.1453 62.4197 25.3068 62.9287 25.3068Z"
				fill="black"
			/>
		</svg>
	);
}
