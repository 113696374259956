import React from "react";

export function Button({ classBtn, btnType, btnClick,btnRef, text, children }) {
	return (
		<button
			className={classBtn}
			type={btnType}
			ref={btnRef}
			onClick={btnClick}
		>
			{text}
			{children}
		</button>
	);
}
