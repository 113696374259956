import { useTranslation, Trans } from "react-i18next";
import style from "./sectionHepatit.module.scss";
import { SocialLink } from "../SocialLink";

export function SectionHepatit({ isMobile }) {
	const { t } = useTranslation();

	return (
		<section className={style.wrapper}>
			<div className={style.container}>
				<div className={style.content}>
					<h1 className={style.title}>{t("hepatit.title")}</h1>
					<p className={style.desc}>
						<Trans i18nKey="hepatit.desc" />
					</p>
					<SocialLink />
				</div>
				<div className={style.image}>
					<img
						src={
							isMobile
								? "/images/hepatit/mobMan.png"
								: "/images/hepatit/man.png"
						}
						alt="Person"
					/>
				</div>
			</div>
		</section>
	);
}
