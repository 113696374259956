export function UKRFlag({ width = 20, height = 20 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_212_2335"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<path
					d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_212_2335)">
				<path
					d="M25 20L25 10L-5 10L-5 20L25 20Z"
					fill="#FCD000"
				/>
				<path
					d="M25 10L25 0L-5 2.01907e-09L-5 10L25 10Z"
					fill="#004AAC"
				/>
			</g>
		</svg>
	);
}
