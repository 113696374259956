export function SlideFoto({ url, className }) {
	return (
		<div className={className}>
			<img
				src={url}
				alt="People"
			/>
		</div>
	);
}
