import { useTranslation } from "react-i18next";
import style from "./sectionNews.module.scss";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { Link } from "react-router-dom";
import { SliderNews } from "./componentsSectionNews/SliderNews";;

export function SectionNews({ isMobile }) {
	const { t } = useTranslation();
	const newsData = useNestedObjectTranslation(uk, "news", "allNews");

	return (
		<section className={style.wrapperNews}>
			<div className={style.container}>
				<div className={style.header_box}>
					<div className={style.box_title}>
						<h3 className={style.title}>{t("news.title")}</h3>
						<h4 className={style.subtitle}>{t("news.subtitle")}</h4>
					</div>
					<Link to={'/news'} className={style.subtitle2}>{t("news.subtitle2")}</Link>
				</div>
				<div className={style.box_news}>
					<SliderNews
						newsData={newsData}
						isMobile={isMobile}
					/>
				</div>
			</div>
		</section>
	);
}
