import style from "./sectionSupport.module.scss";
import { SupportInfo } from "./componentsSectionSupport/SupportInfo";
import { SliderSupport } from "./componentsSectionSupport/SliderSupport";
import { Modal } from "../Modal";
import { ModalContext } from "../../context/ModalContext";
import { AnimatePresence } from "framer-motion";
import { useContext, useState } from "react";
import { Support } from "../Support";

export function SectionSupport() {
	const { isModal, open, close } = useContext(ModalContext);
	const [isSupport, setIsSupport] = useState(false)
	const handleSupportOpen = (member) => {
		open();
		setIsSupport(true)
	};

	const handleSupportClose = () => {
		close();
		setIsSupport(false)
	};

	return (
		<section className={style.wrapperSupport}>
			<div className={style.container}>
				<div className={style.info_body}>
					<SupportInfo onOpen ={handleSupportOpen}/>
				</div>
				<div className={style.support_image}>
					<SliderSupport />
				</div>
			</div>
			<AnimatePresence>
				{isModal && isSupport && (
					<Modal onClose={handleSupportClose}>
						<Support onClose={handleSupportClose} />
					</Modal>
				)}
			</AnimatePresence>
		</section>
	);
}
