import { MenuItem } from "../MenuItem";
import "../../../../i18n";
import style from "./menu.module.scss";
import { uk } from "../../../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../../../utils/hooks/useNestedObjectTranslation";

export function Menu({ isMobile, toggleBurgerMenu }) {
	const menuData = useNestedObjectTranslation(uk, "header", "menu");

	return (
		<>
			{/* Desktop */}
			<>
				{!isMobile && (
					<nav className={style.nav}>
						<ul className={style.nav_list}>
							{menuData.map((menuItem, index) =>
								// Перевірка наявності властивостей у об'єкті menuItem[deepItem]
								Object.keys(menuItem).map((deepItem) => {
									const item = menuItem[deepItem];
									if (item.title) {
										return (
											<MenuItem
												key={index}
												title={item.title}
												subMenu={item.subMenu}
											/>
										);
									}
									return null;
								})
							)}
						</ul>
					</nav>
				)}
			</>
			{/* Mobile */}
			<>
				{isMobile && (
					<>
						{menuData.map((menuItem, index) =>
							// Перевірка наявності властивостей у об'єкті menuItem[deepItem]
							Object.keys(menuItem).map((deepItem) => {
								const item = menuItem[deepItem];
								if (item.title && index !== menuData.length - 1) {
									return (
										<MenuItem
											key={index}
											title={item.title}
											subMenu={item.subMenu}
											isMobile={isMobile}
											toggleBurgerMenu={toggleBurgerMenu}
										/>
									);
								}
								return null;
							})
						)}
					</>
				)}
			</>
		</>
	);
}
