export const boxAnswerAnimation = {
	initial: { opacity: 0, height: 0 },
	animate: {
		opacity: 1,
		transition: { duration: 0.2, ease: "easeOut" },
		height: "auto",
	},
	exit: {
		opacity: 0,
		transition: { duration: 0.2, ease: "easeIn" },
		height: 0,
	},
};
