import style from "./sectionStories.module.scss";
import { useTranslation } from "react-i18next";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { Link } from "react-router-dom";
import { SliderPeople } from "./componentsSectionStories/SliderPeople";

export function SectionStories() {
	const { t } = useTranslation();
	const storiesData = useNestedObjectTranslation(uk, "stories", "people");

	return (
		<section className={style.wrapperStories}>
			<div className={style.container}>
				<div className={style.box_title}>
					<Link to="/stories">
						<h3 className={style.title}>{t("stories.title")}</h3>
					</Link>
					<h4 className={style.subtitle}>{t("stories.subtitle")}</h4>
				</div>
				<div className={style.box_stories}>
					<SliderPeople storiesData={storiesData} />
				</div>
			</div>
		</section>
	);
}
