import style from "./header.module.scss";
import { Burger, Logo } from "../Icons";
import { Menu } from "./headerComponents/Menu";
import { Button } from "../Button";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { Language } from "./headerComponents/Language";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { BurgerMenu } from "./headerComponents/BurgerMenu";
import { Link } from "react-router-dom";

function Header() {
	const { t } = useTranslation();
	// прапор для компонентів, щоб не рендерити іх на мобільних застосунках
	const isMobile = useMediaQuery({ maxWidth: 991 });
	// відкриття бургер-меню
	const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false); //fix
	function toggleBurgerMenu() {
		setIsOpenBurgerMenu(!isOpenBurgerMenu);
	}

	return (
		<header className={style.wrapper}>
			<div className={style.container}>
				{isMobile && (
					<Button
						btnType={"button"}
						classBtn={style.burger_btn}
						text={<Burger />}
						btnClick={toggleBurgerMenu}
					/>
				)}
				<Link to="/" className={style.logo}>
					<Logo />
				</Link>
				{!isMobile && <Menu />}
				<Button
					classBtn={style.nav_btn}
					text={t("header.btn")}
					btnType={"button"}
				/>
				{!isMobile && <Language />}
			</div>
			<AnimatePresence>
				{isMobile && isOpenBurgerMenu && (
					<BurgerMenu
						isOpenBurgerMenu={isOpenBurgerMenu}
						toggleBurgerMenu={toggleBurgerMenu}
						isMobile={isMobile}
					/>
				)}
			</AnimatePresence>
		</header>
	);
}

export default Header;
