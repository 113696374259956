export function Viber({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="#7B519D"
			/>
			<path
				d="M27.4857 24.4244C26.9301 23.977 26.3367 23.5754 25.7527 23.1657C24.587 22.3472 23.5216 22.2833 22.6515 23.6008C22.1627 24.3401 21.48 24.3728 20.7646 24.0482C18.7941 23.1548 17.2725 21.7778 16.3807 19.7747C15.987 18.8886 15.9914 18.094 16.9145 17.4672C17.4033 17.136 17.895 16.7438 17.8558 16.019C17.8049 15.0748 15.5113 11.9183 14.6056 11.5857C14.2308 11.4477 13.8575 11.4564 13.4769 11.585C11.3532 12.2989 10.4744 14.0449 11.3162 16.1127C13.8285 22.2811 18.2509 26.5757 24.3372 29.1969C24.6844 29.3458 25.07 29.4053 25.2654 29.4591C26.6512 29.4729 28.2744 28.1379 28.7436 26.8124C29.1947 25.5378 28.241 25.0323 27.4857 24.4244Z"
				fill="#F1F2F2"
			/>
			<path
				d="M20.6606 11.9757C25.1106 12.6599 27.1631 14.7763 27.7296 19.2583C27.7819 19.6723 27.6279 20.2954 28.2184 20.3071C28.8358 20.3187 28.6869 19.705 28.692 19.291C28.7442 15.0697 25.0634 11.1565 20.7892 11.0119C20.4667 11.0584 19.7999 10.7897 19.7578 11.5124C19.7302 11.999 20.2924 11.9191 20.6606 11.9757Z"
				fill="#F1F2F2"
			/>
			<path
				d="M21.5322 13.2047C21.1037 13.1531 20.5394 12.9512 20.4391 13.5453C20.3353 14.1684 20.9642 14.1053 21.3695 14.196C24.1178 14.8105 25.0743 15.8106 25.5275 18.5371C25.5936 18.9344 25.4622 19.5532 26.1376 19.4508C26.638 19.3745 26.4579 18.8443 26.4993 18.5349C26.5211 15.9166 24.2783 13.5344 21.5322 13.2047Z"
				fill="#F1F2F2"
			/>
			<path
				d="M21.784 15.3452C21.4978 15.3525 21.2182 15.383 21.1129 15.6887C20.9546 16.1449 21.2872 16.2538 21.6242 16.3083C22.7521 16.4891 23.3448 17.1537 23.4581 18.2787C23.4886 18.5837 23.6818 18.8307 23.9767 18.7965C24.3849 18.7479 24.4219 18.384 24.4095 18.039C24.4299 16.7745 23.0027 15.3147 21.784 15.3452Z"
				fill="#F1F2F2"
			/>
		</svg>
	);
}
