/* eslint-disable no-unused-vars */
import { useParams } from "react-router-dom";
import style from "./newsArticle.module.scss";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { uk } from "../../i18n/locales/uk";
import { PageArticle } from "../../components/SectionNews/componentsSectionNews/PageArticle";

export function NewsArticle() {
	const { newsId } = useParams();

	const news = useNestedObjectTranslation(uk, "news", "allNews").map((item) => {
		return item[Object.keys(item)[0]];
	});

	const selectedArticle = news.find((article) => article.id === newsId);

	return (
		<div id="main">
			<section className={style.wrapperNewsArticle}>
				<div className={style.container}>
					{selectedArticle && <PageArticle article={selectedArticle} />}
				</div>
			</section>
		</div>
	);
}
