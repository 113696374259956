import { createContext, useState } from "react";

export const ModalContext = createContext({
	isModal: false,
	open: () => {},
	close: () => {},
});

export function ModalState({ children }) {
	const [isModal, setIsModal] = useState(false);
	const open = () => setIsModal(true);
	const close = () => setIsModal(false);

	return (
		<ModalContext.Provider value={{ isModal, open, close }}>
			{children}
		</ModalContext.Provider>
	);
}
