export function ArrowDown({ width = 16, height = 9, strokeColor = "black" }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 0.5L8 7.5L15 0.5"
				stroke={strokeColor}
			/>
		</svg>
	);
}