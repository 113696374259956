import { useTranslation } from "react-i18next";
import socialData from "./socialData";
import style from "./socialLink.module.scss";

export function SocialLink() {
	const { t } = useTranslation();

	return (
		<div className={style.wrapperLink}>
			<h3 className={style.titleLink}>{t("hepatit.socTitle")}</h3>
			<ul className={style.boxLink}>
				{socialData &&
					socialData.map(({ type, url, icon }) => (
						<li
							className={style.itemLink}
							key={type}
						>
							<a
								href={url}
								target="_blank"
								rel="noreferrer"
							>
								{icon}
							</a>
						</li>
					))}
			</ul>
		</div>
	);
}
