export function ArrowSwiper({ width = 20, height = 15 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.7639 8.12826C19.7766 8.11337 19.7893 8.09881 19.8012 8.08326C19.8128 8.06804 19.8225 8.05249 19.833 8.03694C19.8423 8.02304 19.852 8.00947 19.8606 7.99525C19.8703 7.97936 19.8785 7.96282 19.887 7.94661C19.8949 7.93172 19.9031 7.91749 19.9102 7.90227C19.9176 7.88639 19.9236 7.87017 19.93 7.85429C19.9367 7.83808 19.9434 7.82186 19.949 7.80532C19.9546 7.78911 19.9587 7.77256 19.9632 7.75635C19.968 7.73948 19.9732 7.72293 19.977 7.70573C19.9811 7.68653 19.9837 7.66701 19.987 7.64749C19.9893 7.63293 19.9926 7.61903 19.9941 7.60414C20.002 7.53499 20.002 7.46551 19.9941 7.39635C19.9926 7.38179 19.9896 7.36756 19.987 7.35301C19.9841 7.33348 19.9814 7.31396 19.977 7.29477C19.9732 7.27757 19.9676 7.26102 19.9632 7.24415C19.9587 7.22793 19.9546 7.21139 19.949 7.19518C19.9434 7.17863 19.9363 7.16242 19.93 7.1462C19.9236 7.12999 19.9173 7.11411 19.9102 7.09823C19.9031 7.08301 19.8949 7.06878 19.887 7.05389C19.8785 7.03768 19.8703 7.02113 19.8606 7.00525C19.852 6.99102 19.8423 6.97746 19.833 6.96356C19.8225 6.94801 19.8128 6.93246 19.8012 6.91723C19.7893 6.90168 19.7766 6.88713 19.7639 6.87224C19.7535 6.85966 19.7434 6.84709 19.7322 6.83451C19.7076 6.80771 19.6815 6.78224 19.6539 6.75775L12.3802 0.307223C11.9183 -0.102408 11.1687 -0.102408 10.7069 0.307223C10.2446 0.716854 10.2446 1.38126 10.7069 1.79123L15.9607 6.45069H1.18306C0.52941 6.45069 0 6.92054 0 7.49992C0 8.07929 0.529783 8.54914 1.18306 8.54914H15.9607L10.7069 13.2086C10.2446 13.6182 10.2446 14.2826 10.7069 14.6926C10.9378 14.8974 11.2407 15 11.5433 15C11.8463 15 12.1488 14.8974 12.3798 14.6926L19.6535 8.24175C19.6811 8.21727 19.7072 8.19179 19.7318 8.16499C19.7438 8.15374 19.7535 8.14083 19.7639 8.12826Z"
				fill="#28609D"
			/>
			<path
				d="M19.7639 8.12826C19.7766 8.11337 19.7893 8.09881 19.8012 8.08326C19.8128 8.06804 19.8225 8.05249 19.833 8.03694C19.8423 8.02304 19.852 8.00947 19.8606 7.99525C19.8703 7.97936 19.8785 7.96282 19.887 7.94661C19.8949 7.93172 19.9031 7.91749 19.9102 7.90227C19.9176 7.88639 19.9236 7.87017 19.93 7.85429C19.9367 7.83808 19.9434 7.82186 19.949 7.80532C19.9546 7.78911 19.9587 7.77256 19.9632 7.75635C19.968 7.73948 19.9732 7.72293 19.977 7.70573C19.9811 7.68653 19.9837 7.66701 19.987 7.64749C19.9893 7.63293 19.9926 7.61903 19.9941 7.60414C20.002 7.53499 20.002 7.46551 19.9941 7.39635C19.9926 7.38179 19.9896 7.36756 19.987 7.35301C19.9841 7.33348 19.9814 7.31396 19.977 7.29477C19.9732 7.27757 19.9676 7.26102 19.9632 7.24415C19.9587 7.22793 19.9546 7.21139 19.949 7.19518C19.9434 7.17863 19.9363 7.16242 19.93 7.1462C19.9236 7.12999 19.9173 7.11411 19.9102 7.09823C19.9031 7.08301 19.8949 7.06878 19.887 7.05389C19.8785 7.03768 19.8703 7.02113 19.8606 7.00525C19.852 6.99102 19.8423 6.97746 19.833 6.96356C19.8225 6.94801 19.8128 6.93246 19.8012 6.91723C19.7893 6.90168 19.7766 6.88713 19.7639 6.87224C19.7535 6.85966 19.7434 6.84709 19.7322 6.83451C19.7076 6.80771 19.6815 6.78224 19.6539 6.75775L12.3802 0.307223C11.9183 -0.102408 11.1687 -0.102408 10.7069 0.307223C10.2446 0.716854 10.2446 1.38126 10.7069 1.79123L15.9607 6.45069H1.18306C0.52941 6.45069 0 6.92054 0 7.49992C0 8.07929 0.529783 8.54914 1.18306 8.54914H15.9607L10.7069 13.2086C10.2446 13.6182 10.2446 14.2826 10.7069 14.6926C10.9378 14.8974 11.2407 15 11.5433 15C11.8463 15 12.1488 14.8974 12.3798 14.6926L19.6535 8.24175C19.6811 8.21727 19.7072 8.19179 19.7318 8.16499C19.7438 8.15374 19.7535 8.14083 19.7639 8.12826Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M19.7639 8.12826C19.7766 8.11337 19.7893 8.09881 19.8012 8.08326C19.8128 8.06804 19.8225 8.05249 19.833 8.03694C19.8423 8.02304 19.852 8.00947 19.8606 7.99525C19.8703 7.97936 19.8785 7.96282 19.887 7.94661C19.8949 7.93172 19.9031 7.91749 19.9102 7.90227C19.9176 7.88639 19.9236 7.87017 19.93 7.85429C19.9367 7.83808 19.9434 7.82186 19.949 7.80532C19.9546 7.78911 19.9587 7.77256 19.9632 7.75635C19.968 7.73948 19.9732 7.72293 19.977 7.70573C19.9811 7.68653 19.9837 7.66701 19.987 7.64749C19.9893 7.63293 19.9926 7.61903 19.9941 7.60414C20.002 7.53499 20.002 7.46551 19.9941 7.39635C19.9926 7.38179 19.9896 7.36756 19.987 7.35301C19.9841 7.33348 19.9814 7.31396 19.977 7.29477C19.9732 7.27757 19.9676 7.26102 19.9632 7.24415C19.9587 7.22793 19.9546 7.21139 19.949 7.19518C19.9434 7.17863 19.9363 7.16242 19.93 7.1462C19.9236 7.12999 19.9173 7.11411 19.9102 7.09823C19.9031 7.08301 19.8949 7.06878 19.887 7.05389C19.8785 7.03768 19.8703 7.02113 19.8606 7.00525C19.852 6.99102 19.8423 6.97746 19.833 6.96356C19.8225 6.94801 19.8128 6.93246 19.8012 6.91723C19.7893 6.90168 19.7766 6.88713 19.7639 6.87224C19.7535 6.85966 19.7434 6.84709 19.7322 6.83451C19.7076 6.80771 19.6815 6.78224 19.6539 6.75775L12.3802 0.307223C11.9183 -0.102408 11.1687 -0.102408 10.7069 0.307223C10.2446 0.716854 10.2446 1.38126 10.7069 1.79123L15.9607 6.45069H1.18306C0.52941 6.45069 0 6.92054 0 7.49992C0 8.07929 0.529783 8.54914 1.18306 8.54914H15.9607L10.7069 13.2086C10.2446 13.6182 10.2446 14.2826 10.7069 14.6926C10.9378 14.8974 11.2407 15 11.5433 15C11.8463 15 12.1488 14.8974 12.3798 14.6926L19.6535 8.24175C19.6811 8.21727 19.7072 8.19179 19.7318 8.16499C19.7438 8.15374 19.7535 8.14083 19.7639 8.12826Z"
				fill="black"
				fillOpacity="0.2"
			/>
			<path
				d="M19.7639 8.12826C19.7766 8.11337 19.7893 8.09881 19.8012 8.08326C19.8128 8.06804 19.8225 8.05249 19.833 8.03694C19.8423 8.02304 19.852 8.00947 19.8606 7.99525C19.8703 7.97936 19.8785 7.96282 19.887 7.94661C19.8949 7.93172 19.9031 7.91749 19.9102 7.90227C19.9176 7.88639 19.9236 7.87017 19.93 7.85429C19.9367 7.83808 19.9434 7.82186 19.949 7.80532C19.9546 7.78911 19.9587 7.77256 19.9632 7.75635C19.968 7.73948 19.9732 7.72293 19.977 7.70573C19.9811 7.68653 19.9837 7.66701 19.987 7.64749C19.9893 7.63293 19.9926 7.61903 19.9941 7.60414C20.002 7.53499 20.002 7.46551 19.9941 7.39635C19.9926 7.38179 19.9896 7.36756 19.987 7.35301C19.9841 7.33348 19.9814 7.31396 19.977 7.29477C19.9732 7.27757 19.9676 7.26102 19.9632 7.24415C19.9587 7.22793 19.9546 7.21139 19.949 7.19518C19.9434 7.17863 19.9363 7.16242 19.93 7.1462C19.9236 7.12999 19.9173 7.11411 19.9102 7.09823C19.9031 7.08301 19.8949 7.06878 19.887 7.05389C19.8785 7.03768 19.8703 7.02113 19.8606 7.00525C19.852 6.99102 19.8423 6.97746 19.833 6.96356C19.8225 6.94801 19.8128 6.93246 19.8012 6.91723C19.7893 6.90168 19.7766 6.88713 19.7639 6.87224C19.7535 6.85966 19.7434 6.84709 19.7322 6.83451C19.7076 6.80771 19.6815 6.78224 19.6539 6.75775L12.3802 0.307223C11.9183 -0.102408 11.1687 -0.102408 10.7069 0.307223C10.2446 0.716854 10.2446 1.38126 10.7069 1.79123L15.9607 6.45069H1.18306C0.52941 6.45069 0 6.92054 0 7.49992C0 8.07929 0.529783 8.54914 1.18306 8.54914H15.9607L10.7069 13.2086C10.2446 13.6182 10.2446 14.2826 10.7069 14.6926C10.9378 14.8974 11.2407 15 11.5433 15C11.8463 15 12.1488 14.8974 12.3798 14.6926L19.6535 8.24175C19.6811 8.21727 19.7072 8.19179 19.7318 8.16499C19.7438 8.15374 19.7535 8.14083 19.7639 8.12826Z"
				fill="black"
				fillOpacity="0.2"
			/>
		</svg>
	);
}