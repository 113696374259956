import { useTranslation } from "react-i18next";
import style from "./emptyStory.module.scss";
import { Link } from "react-router-dom";

export function EmptyStory() {
	const { t } = useTranslation();

	return (
		<div className={style.empty_body}>
			<h3 className={style.title}>{t("stories.empty")}</h3>
			<Link
				to={"/stories"}
				className={style.btn_back}
			>
				<span>{t("stories.btnBack")}</span>
			</Link>
		</div>
	);
}
