import style from './footer.module.scss'

function Footer() {

	return (
		<footer className={style.wrapper}>

		</footer>
	)
}

export default Footer;