import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import style from "./sliderSupport.module.scss";
import { SlideFoto } from "../SlideFoto";
import { supportImg } from "../../../../utils/dataImages";

export function SliderSupport() {

	return (
		<Swiper
			modules={[Autoplay, FreeMode, Navigation]}
			loop={true}
			// slidesPerGroup={1}
			// slidesPerView={3}
			// slidesPerView={"auto"}
			spaceBetween={0}
			speed={500}
			autoplay={{
				delay: 5000,
				disableOnInteraction: false,
			}}
		>
			{supportImg.map((url, index) => (
				<SwiperSlide key={index}>
					<SlideFoto
						key={index}
						url={url}
						className={style.box_img}
					/>
				</SwiperSlide>
			))}
			{/* <SwiperSlide className={style.box_img} /> */}
		</Swiper>
	);
}
