import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { ArrowDown } from "../../../Icons";
import style from "./menuItem.module.scss";
import { wrapperAnimation, linkAnimation } from "../../../../utils/animation";

export function MenuItem({ title, subMenu, toggleBurgerMenu, isMobile }) {
	const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
	const subMenuRef = useRef(null); // Посилання на підменю
	const menuItemRef = useRef(null); // Посилання на пункт головного меню

	const handleClickOpenSubMenu = () => {
		setIsOpenSubMenu(!isOpenSubMenu);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isOpenSubMenu &&
				subMenuRef.current &&
				menuItemRef.current &&
				!subMenuRef.current.contains(event.target) && // Перевірка, чи клік відбувся не на підменю
				!menuItemRef.current.contains(event.target) // Перевірка, чи клік відбувся не на пункт меню
			) {
				setIsOpenSubMenu(false); // Закриття підменю
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isOpenSubMenu]);

	return (
		<>
			{/* Desktop */}
			<>
				{!isMobile &&
					(subMenu ? (
						<li
							ref={menuItemRef}
							className={`${style.mainItem} ${isOpenSubMenu && style.active}`}
							onClick={handleClickOpenSubMenu}
						>
							<span>{title}</span>
							<span className={`${style.arrow} ${isOpenSubMenu && style.up}`}>
								<ArrowDown />
							</span>
							{/***SubMenu***/}
							<AnimatePresence>
								{isOpenSubMenu && (
									<motion.ul
										ref={subMenuRef}
										className={style.subWrapper}
										{...wrapperAnimation}
									>
										{Object.keys(subMenu).map((key, index) => (
											<motion.li
												className={style.subItem}
												key={index}
												{...linkAnimation(index)}
											>
												<a href="##">{subMenu[key]}</a>
											</motion.li>
										))}
									</motion.ul>
								)}
							</AnimatePresence>
						</li>
					) : (
						<li className={style.item}>
							<a href="##">{title}</a>
						</li>
					))}
			</>
			{/* Mobil */}
			<>
				{isMobile &&
					(subMenu ? (
						<div
							ref={menuItemRef}
							className={`${style.mainItemMob} ${
								isOpenSubMenu && style.active
							}`}
							onClick={handleClickOpenSubMenu}
						>
							<span>{title}</span>
							<span
								className={`${style.arrowMob} ${isOpenSubMenu && style.up}`}
							>
								<ArrowDown strokeColor={"#03071E"} />
							</span>
							{/***SubMenu***/}
							<AnimatePresence>
								{isOpenSubMenu && (
									<motion.ul
										ref={subMenuRef}
										className={style.subWrapperMob}
										{...wrapperAnimation}
									>
										{Object.keys(subMenu).map((key, index) => (
											<motion.li
												className={style.subItemMob}
												key={index}
												{...linkAnimation(index)}
												onClick={toggleBurgerMenu}
											>
												<a href="##">{subMenu[key]}</a>
											</motion.li>
										))}
									</motion.ul>
								)}
							</AnimatePresence>
						</div>
					) : (
						<div
							className={style.itemMob}
							onClick={toggleBurgerMenu}
						>
							<a href="##">{title}</a>
						</div>
					))}
			</>
		</>
	);
}
