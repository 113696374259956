import { useTranslation } from "react-i18next";
import style from "./sectionPartners.module.scss";
import { partnersImg } from "../../utils/dataImages";
import { SlidePartner } from "./componentsSectionPartners/SlidePartner";
import { SliderPertners } from "./componentsSectionPartners/SliderPertners";

export function SectionPartners({ isMobile }) {
	const { t } = useTranslation();

	return (
		<section className={style.wrapperPartners}>
			<div className={style.container}>
				<div className={style.box_title}>
					<h3 className={style.title}>{t("partners.title")}</h3>
					<h4 className={style.subtitle}>{t("partners.subtitle")}</h4>
				</div>
				<div className={style.box_partners}>
					{!isMobile ? (
						partnersImg.map((url, index) => (
							<SlidePartner
								key={index}
								url={url}
							/>
						))
					) : (
						<SliderPertners />
					)}
				</div>
			</div>
		</section>
	);
}
