import { useTranslation } from "react-i18next";
import style from "./sectionContacts.module.scss";

export function SectionContacts() {
	const { t } = useTranslation();

	return (
		<section className={style.wrapperSectionContacts}>
			<div className={style.container}>
				<h3 className={style.title}>{t("contacts.title")}</h3>
				<div className={style.content_wrapper}>
					<div className={style.contacts_box}>
						<div className={style.info_box}>
							<h4 className={style.info_title}>{t("contacts.address")}</h4>
							<p className={style.info_desc}>{t("contacts.address2")}</p>
							<p className={style.info_desc}>{t("contacts.address3")}</p>
						</div>
						<div className={style.info_box}>
							<h4 className={style.info_title}>{t("contacts.phone")}</h4>
							<p className={style.info_desc}>{t("contacts.phone2")}</p>
							<p className={style.info_desc}>{t("contacts.phone3")}</p>
						</div>
						<div className={style.info_box}>
							<h4 className={style.info_title}>{t("contacts.post")}</h4>
							<p className={style.info_desc}>{t("contacts.post2")}</p>
						</div>
					</div>
					<a
						href="https://maps.app.goo.gl/9GXQgVwbaAK8ArYb7"
						target="blank"
						className={style.image}
					>
						<img
							src="/images/contacts/map.png"
							alt="Map"
						/>
					</a>
				</div>
			</div>
		</section>
	);
}
