import { Facebook, Instagram, Messenger, Telegram, Twitter, Viber, Whatsapp } from "../Icons";

const socialData = [
	{
		type: "Messenger",
		url: "https://www.messenger.com",
		icon: <Messenger />,
	},
	{
		type: "Instagram",
		url: "https://www.instagram.com",
		icon: <Instagram />,
	},
	{
		type: "Telegram",
		url: "https://t.me",
		icon: <Telegram />,
	},
	{
		type: "Viber",
		url: "https://www.viber.com",
		icon: <Viber />,
	},
	{
		type: "Facebook",
		url: "https://www.facebook.com",
		icon: <Facebook />,
	},
	{
		type: "Whatsapp",
		url: "https://www.whatsapp.com",
		icon: <Whatsapp />,
	},
	{
		type: "Twitter",
		url: "https://twitter.com",
		icon: <Twitter />,
	},
];

export default socialData;
