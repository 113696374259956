export const en = {
	//***Header***//
	header: {
		menu: {
			about: {
				title: "About Us",
				subMenu: {
					item1: "Our organization",
					item2: "News",
					item3: "Partners",
					item4: "We are in the media",
				},
			},
			treatment: {
				title: "Diagnosis and Treatment",
				subMenu: {
					item1: "Diagnosis of hepatitis B, C and D",
					item2: "Treatment of hepatitis B, C and D",
					item3: "Diet and regimen during treatment",
					item4: "Free treatment of hepatitis B, C and D",
				},
			},
			patients: {
				title: "Patients",
				subMenu: {
					item1: "What to do if you are denied free diagnostics or treatment?",
					item2: "Stories of hepatitis winners",
					item3: "Question - Answer",
				},
			},
			projects: {
				title: "Projects",
			},
			specialists: {
				title: "Specialists",
			},
			contacts: {
				title: "Contacts",
			},
		},
		btn: "Support",
		burger: {
			title: 'Public organisation "BCD"',
			lang: "Language",
		},
	},

	//*** SectionHepatit ***/
	hepatit: {
		title: "We will help You to be free from hepatitis",
		desc: "Our organisation addresses the problem of the hepatitis virus epidemic in Ukraine and the world. Over the period of our activity, we have provided assistance to more than <strong>5000</strong> people.",
		socTitle: "Share this link in social networks:",
	},

	//*** SectionAdvantages ***/
	advantages: {
		title: "Our advantages",
		subtitle: "Why choose us?",
		desc: "We work with people and for people, we provide simplicity, convenience, reliability and the opportunity to get exactly what you need.",
		info: {
			column1: {
				url: "/images/advantages/1.svg",
				title: "Efficiency",
				desc: "An organisation created by patients who have successfully beaten hepatitis C",
			},
			column2: {
				url: "/images/advantages/2.svg",
				title: "Motivation",
				desc: "High competence and motivation of the team for the result",
			},
			column3: {
				url: "/images/advantages/3.svg",
				title: "We are trusted",
				desc: "Our organisation has a high level of trust from customers and partners",
			},
			column4: {
				url: "/images/advantages/4.svg",
				title: "Reliability",
				desc: "Good cooperation with stakeholders in the field of hepatitis viruses has been established",
			},
		},
	},

	//*** SectionOrganization ***/
	organization: {
		title: "Our organisation",
		subtitle:
			"BCD is a community of people solving problems related to hepatitis",
		desc1:
			"We are united to overcome the epidemic of viral hepatitis in Ukraine.",
		desc2:
			"We are a team of professional volunteers: social consultants, the best infectious disease and hepatology doctors in Ukraine, ultrasound diagnosticians and employees of leading laboratories with excellent reputation.",
		btn: "Read More",
	},

	//*** SectionTeam ***/ без переводу
	team: {
		title: "Наша команда",
		subtitle: "Познайомся з командою BCD",
		desc: "Команда провадить комплекс заходів із забезпечення безперервності отримання медико-соціальних послуг пацієнтами з ВГ(вірусом гепатиту) із ключових груп та їх близькому оточенню, в т.ч. тих хто опинився за кордоном",
		members: {
			member1: {
				url: "/images/team/1.png",
				name: "Войналович Юрій",
				position: "Керівник та експерт з вірусних гепатитів",
				desc: "За покликанням миротворець, комунікатор, аудитор,”вічний студент”, фабрикант ідей та рішень. Натхненний Любов`ю Господа, чудовою сім’єю, талановитими  та  вірними друзями.",
			},
			member2: {
				url: "/images/team/2.png",
				name: "Кочеткова Олена",
				position: "Головний бухгалтер",
				desc: "Я мама прекрасної донечки. Дуже люблю читати, і ті знання  та розширена перспектива, котрі  знаходжу завдяки читанню, допомагають мені розвивати свої здібності та навички в роботі, та в повсякденному житті.",
			},
			member3: {
				url: "/images/team/3.png",
				name: "Раус Ірина",
				position: "Дитячий лікар-інфекціоніст",
				desc: "Пам’ятайте, що діти щасливі тільки при здорових батьках. Піклуйтеся та бережіть здоров’я поки воно є. Власним прикладом формуйте відповідальне ставлення до здоров’я у дітей. Тоді вони виростуть здоровими та не матимуть потребу в лікарях.",
			},
			member4: {
				url: "/images/team/4.png",
				name: "Бризгалов Олексій",
				position: "Завідувач діагностичного відділення",
				desc: "Люблю здоровий спосіб життя, динамічний спорт та подорожі.",
			},
			member5: {
				url: "/images/team/5.png",
				name: "Рихальська Катерина",
				position: "Лікар-інфекціоніст",
				desc: "Художник свого життя та людського тіла. Завзята мандрівниця, яка підкорила не один (пів) марафон. Віддана мама і донька.",
			},
			member6: {
				url: "/images/team/6.png",
				name: "Мельник Артем",
				position: "Координатор проєктної діяльності",
				desc: "Експерт з хімічної залежності, помічник та консультант депутата Київської Міської Ради,щасливий чоловік чудової дружини,віруючий Християнин. Моє покликання бути Лідером позитивних змін в Україні.",
			},
			member7: {
				url: "/images/team/7.png",
				name: "Лукаш Дмитро",
				position: "Лікар Інфекціоніст",
				desc: "Спеціалізуюсь в області діагностування і лікування інфекційних захворювань та в гепатології. Пройшов навчання в провідних фахівців України. Лікувальну практику здійснюю відповідно до сучасних міжнародних рекомендацій.",
			},
			member8: {
				url: "/images/team/8.png",
				name: "Войналович Владислава",
				position: "Магістр права та перекладач",
				desc: "Ліквідатор бар’єрів та кордонів міжнародної співпраці. Натхненна любов’ю до людей різних світоглядів та культур, подорожами та мистецтвом танцю.",
			},
			member9: {
				url: "/images/team/9.png",
				name: "Захаров Олег",
				position: "Майстер бухгалтерії та фінансів",
				desc: "Волонтер за покликанням. Чудовий чоловік своєї дружини та люблячий батько прекрасної доньки.Захоплюсь туризмом, спортом та люблю читати книги.",
			},
			member10: {
				url: "/images/team/10.png",
				name: "Мотузенко Юрій",
				position: "Координатор процесів",
				desc: "Експерт зі здорового способу життя , виведення із залежностей. Надихаюсь коли читаю книжки та пізнаю щось нове. Люблю людей, свою сім`ю, свою країну та риболовлю. Вірю що зі звичайних людей Бог може зробити надзвичайних.",
			},
			member11: {
				url: "/images/team/11.png",
				name: "Колесов Андрій",
				position: "Програмний менеджер",
				desc: "Християнин. Прихильник здорового способу життя, фізичних вправ, відпочинку на природі та риболовлі.",
			},
			member12: {
				url: "/images/team/12.png",
				name: "Французова Тетяна",
				position: "Асистентка програмного менеджера",
				desc: "Кохана і любляча дружина, мама чотирьох прекрасних дівчаток. Люблю Бога, людей і життя. Жіночий психолог, експерт в галузі відносин та співзалежності, тренер по роботі з молоддю та підлітками.",
			},
		},
	},

	//*** SectionStories ***/ без переводу
	stories: {
		title: "Історії переможців гепатиту",
		subtitle: "Реальні історії людей, котрі перемогли гепатит",
		btn: "Читати",
		btnBack: "Назад",
		empty: "Історія незабаром буде завантажена...",
		people: {
			person1: {
				id: 1,
				url: "/images/stories/1.png",
				name: "Юрій Войналович",
				desc1:
					"«Чітко ставте мету-тоді вона стане ресурсом,що допоможе вам її досягти».",
				desc2:
					"Це слова голови БО «Мережа» Дмитра Шерембея (Dima Sherembey) на тренінгу в березні 2016 року. Вони змусили мене закарбувати ціль на той рік , а саме — перемогти вірусний гепатит С.",
				win: "Переміг гепатит",
				winMob: "Юрій Войналович переміг гепатит",
			},
			person2: {
				id: 2,
				url: "/images/stories/2.png",
				name: "Олександр",
				desc1:
					"Коли єдина нирка відмовила, Олександру  не лише вдалося безкоштовно отримати донорський орган за кордоном, а й зупинити прогресування цирозу печінки – гепатитом C чоловік заразився під час одного зі сеансів гемодіалізу…",
				desc2:
					"“Завжди дуже любив м’ясо – довелося полюбити вівсяну кашу”, – всміхається 43-річний киянин.",
				win: "Переміг гепатит",
				winMob: "Олексндр переміг гепатит",
				ownStory: {
					title: "Олександр переміг гепатит",
					date: "22 Січня 2021",
					author: "Автор:",
					nameAuthor: " Вольдемар",
					url1: "/images/stories/story2/1.png",
					text1:
						"Коли єдина нирка відмовила, Олександру Вецу не лише вдалося безкоштовно отримати донорський орган за кордоном, а й зупинити прогресування цирозу печінки – гепатитом C чоловік заразився під час одного зі сеансів гемодіалізу… “Завжди дуже любив м’ясо – довелося полюбити вівсяну кашу”, – всміхається 43-річний киянин. До хвороби УЗД чоловік ніколи не робив, адже нічого його не турбувало. Аж поки одного разу не зауважив, що у нього сильно набрякли ноги та обличчя, а кількість сечі – різко зменшилася. Тоді й забив на сполох.",
					text2:
						"“Лікар запитав мене: “Чоловіче, куди ти подів свою нирку? Продав?” “Дуже смішно”, – відповів я, хоча насправді мені було не до сміху”",
					text3:
						"Чоловік здав аналіз на креатинін – той показав перевищення показника норми майже у 15 разів! Від лікаря, який проводив УЗД нирок, Олександр сподівався почути попередній діагноз. Натомість почув запитання, на яке не знав як відповісти: “Чоловіче, куди ти подів свою нирку? Продав?” “Дуже смішно!” – відповів розгублений пацієнт, хоча насправді йому було не до сміху. Єдина нирка катастрофічно зменшилася у розмірі і вже не справлялася із навантаженням. Лікарі сформували чоловіку судинний доступ (т. зв. фістулу) – зшили артерію та вену – і через тиждень відправили хворого на гемодіаліз. Як правило, фістулі дають місяць, аби вона “дозріла”. Але так довго чекати Олександр не міг – без процедури очистки крові поза організмом помер би. За фахом Олександр – агроном. Розповідає: впродовж 15 років практично щодня контактував з пестицидами. Тож якби не це, хто-зна, можливо, ниркова недостатність розвинулася б у нього набагато пізніше…",
					text4:
						"“2 січня мені зателефонували з Білорусії і сказали, що у мене є 10 годин, аби лягти на операційний стіл. Якщо не встигну, трупний орган викинуть у смітник”",
					url2: "/images/stories/story2/2.png",
					descUrl2: "Олександр переміг гепатит С",
					text5:
						"Сеанси замісної ниркової терапії чоловік проходив тричі на тиждень по чотири години чотири роки поспіль. Зізнається: йому насправді пощастило. Всі його “друзі по нещастю”, які проходили гемодіаліз разом із ним, уже відправилися на той світ. У них, як і в Олександра, розвинулася індивідуальна непереносимість “штучної нирки”. “Щоразу коли мене підключали до апарату, боявся, що цей день може стати останнім у моєму житті”, – зізнається “Еспресо.Захід” чоловік.",
					text6:
						"Нову нирку Олександр отримав два роки тому… “під ялинку”. “2 січня мені зателефонували з Білорусії і сказали, що у мене є 10 годин, аби лягти на операційний стіл. Якщо не встигну, трупний орган викинуть у смітник. Обдзвонив друзів – всі напідпитку, святкують. Летіти літаком – не варіант. Літак летить до Мінська, а від Мінська до Бреста, де мене мали прооперувати – така ж відстань, як від Києва до Бреста. Знайшов друга, який погодився відвезти мене з дружиною на авто. О 3.00 мені провели HLA-типування (оцінку сумісності донора і реципієнта. – Авт.) – орган підходив за усіма параметрами. О 6.00 я вже прокинувся з пересадженою ниркою, яка відразу ж почала виділяти сечу, хоча до цього я взагалі не мочився”, – розповідає киянин. ",
					titleUrl3:
						"“Без належного лікування гепатиту лікарі давали мені пів року. Потрібно було негайно діяти”",
					url3: "/images/stories/story2/3.png",
					descUrl3:
						"Олександр Вец з коханою дружиною Іриною.“Мене врятувала її любов!” – зізнається чоловік.",
				},
			},
			person3: {
				id: 3,
				url: "/images/stories/3.png",
				name: "Наталія та її син Максим",
				desc1:
					"У підлітковому віці я вважала, що доля зі мною обійшлася жорстоко і я народилася не в тому місці й не в тій родині, саме тому я без жалю влилася в компанію так званих «бригадних хлопців» у лихі 90-ті. Коли мені було 19 років, мій перший цивільний чоловік вмовив мене спробувати героїн. Я й гадки не мала, як це круто змінить моє життя, і тому я погодилася.",
				win: "Перемогли гепатит",
				winMob: "Наталія та її син Максим перемогли гептит",
			},
			person4: {
				id: 4,
				url: "/images/stories/4.png",
				name: "Денис",
				desc1:
					"Я проживаю у Хмельницькій області, у маленькому містечку. Зараз я на пенсії через інвалідність, маю 2 групу. Основний діагноз – цироз печінки.",
				desc2:
					"Про свій діагноз я дізнався в серпні 2018 року. Тоді я лікував цироз і лікарка звернула увагу на мої аналізи, точніше, на відсутність позитивної динаміки в лікуванні. Мабуть, вона щось запідозрила й направила мене на обстеження щодо вірусних гепатитів.",
				win: "Переміг гепатит",
				winMob: "Денис переміг гепатит",
			},
			person5: {
				id: 5,
				url: "/images/stories/5.png",
				name: "Константин Варига",
				desc1:
					"Життя завжди дарує несподівані сюрпризи. Тобі здається, що ти готовий до усього. Саме так було і в моєму випадку, В 2000 році я прийшов здати аналізи на гепатити . В 1996 году я перехворів на гепатит В, тому й вирішив слідкувати за станом своєї печінки. “У вас гепатит С, наступний” – ось все, що почув я. Що це таке, лікар не пояснив. Не буду брехати, це було очікувано , але, все рівно, хотілось би почути інший вердикт.",
				win: "Переміг гепатит",
				winMob: "Константин Варига переміг гепатит",
			},
			person6: {
				id: 6,
				url: "/images/stories/6.png",
				name: "Сергій Бородавкін",
				desc1:
					"Хочу поділитися з вами своєю історією . У 2015 році я дізнався про наявність в моїй крові вірусу гепатиту С. Після знайомства з більш детальною інформацією про цю хворобу, я зрозумів, що у мене велика проблема, так як її ще називають “лагідний вбивця”. Я почав шукати способи вирішення цієї проблеми.",
				win: "Переміг гепатит",
				winMob: "Сергій Бородавкін переміг гепатит",
			},
			person7: {
				id: 7,
				url: "/images/stories/7.png",
				name: "Турція Олена",
				desc1:
					"Я перемогла, ВІРУСУ НЕМАЄ!!! А починалося все дуже невесело. Я живу в Стамбулі, і для того, аби отримати ДТЖ (дозвіл на тимчасове проживання) необхідно було здати аналізи. Так я дізналася, що у мене гепатит С, генотип 1b та перша стадія фіброзу печінки.",
				win: "Перемогла гепатит",
				winMob: "Турція Олена перемогла гепатит",
			},
			person8: {
				id: 8,
				url: "/images/stories/8.png",
				name: "Леонід Дашков",
				desc1:
					"Ніхто не народжується бандитом, наркоманом чи алкоголіком. Через різні обставини ми інколи перечіпляємося і вибираємо не той шлях. Так сталося й в моєму житті. Поки….поки я не сказав: ”стоп, я не хочу так жити”, і поїхав у християнський реабілітаційний центр.",
				win: "Переміг гепатит",
				winMob: "Леонід Дашков переміг гепатит",
			},
			person9: {
				id: 9,
				url: "/images/stories/9.png",
				name: "Віка Кірєєва",
				desc1:
					"Ділюся з вами своєю історією перемоги. У вересні 2018 року я вийшла заміж. Весілля відгуляли, час минав і ми почали думати про дітей. Здали аналізи. У чоловіка все гаразд. У меня діагноз: хронічний вірусний гепатит C! Розгубленість, страх, розчарування, невизначеність-ціла палітра почуттів та переживань.",
				win: "Перемогла гепатит",
				winMob: "Віка Кірєєва перемогла гепатит",
			},
			person10: {
				id: 10,
				url: "/images/stories/10.png",
				name: "Валентина Данилова",
				desc1:
					"Мене звати Валентина. У моєму житті сталася надзвичайна подія. Я хочу поділитися з вами своєю історією. У 1997 році я дізналася, що хвора на вірусний гепатит С.",
				desc2:
					"Це був вирок з відстрочкою. Серед людей гепатит С називають «лагідний вбивця», так як він вважався невиліковною хворобою, міг довго себе не проявляти, а потім, несподівано виявлявся цироз печінки.",
				win: "Перемогла гепатит",
				winMob: "Валентина Данилова перемогла гепатит",
			},
		},
	},

	//*** SectionSupport***/ без переводу
	support: {
		title: "Підтримати",
		subtitle: "Допоможіть нам вирішити проблему гепатиту в Україні та світі",
		desc: "Основні напрями нашої роботи це адвокація прав та надання фахової соціальної і медичної допомоги пацієнтам з діагнозом хронічний Вірусний Гепатит(далі-ВГ) B,C і D в тому числі ко-інфекцією ВІЛ/ТБ. В Україні оціночно 2,5 мільйона людей живуть з ВГ але через брак ресурсів та інформації на сьогодні тільки 130 тисяч звернулось за медичною допомогою.",
		title2: "Реквізити для підтримки",
		company: "Компанія:",
		info1: "БСД, ГО",
		current: "Розрахунковий рахунок:",
		info2: "UA683052990000026008016213422",
		code: "ЄДРПОУ:",
		info3: "43181116",
		service: "Назва послуги:",
		info4: "Благодійна допомога",
	},

	//*** SectionPartners ***/ без переводу
	partners: {
		title: "Наші партнери",
		subtitle: "Разом робимо Україну і світ здоровішими",
	},

	//*** SectionQuestions ***/ без переводу
	questions: {
		title: "Питання-відповіді",
		subtitle: "Відповіді на розповсюджені питання",
		allQuestions: {
			quest1: {
				title: "Що таке гепатит?",
				answer: {
					desc: "Гепатит — це запалення тканини печінки.",
					desc2: "Його можуть викликати:",
					li1: "токсичні речовини, у тому числі деякі ліки;",
					li2: "відкладення жирової тканини в печінці при ожирінні;",
					li3: "зловживання спиртним;",
					li4: "віруси гепатиту.І це основна причина запальних захворювань печінки.",
				},
			},
			quest2: {
				title: "Чим небезпечний гепатит?",
				answer: {
					desc: "Якщо запалення в тканині печінки хронічне, організм не встигає відновлювати пошкоджені клітини і заміщує їх сполучною тканиною. В результаті змінюється структура органу, розвивається фіброз, цироз і навіть рак печінки.",
					desc2:
						"Через ці зміни печінка не може ефективно виконувати свої функції, виводити токсини, що неминуче негативно позначається на стані організму. При цьому хронічний гепатит себе практично ніяк не проявляє — у нього немає специфічних яскраво виражених симптомів. Хронічні гепатити знижують якість життя і можуть призвести до розвитку цирозу і раку печінки.",
				},
			},
			quest3: {
				title: "Якщо я зарився гепатитом, я скоро помру?",
				answer: {
					desc: "Ні. Хронічний вірусний гепатит — небезпечне, але не швидкоплинне захворювання., до того ж успішно піддається лікуванню. Може пройти 15-20 років, а іноді і більше, перш ніж з’являться небезпечні для життя наслідки.",
				},
			},
			quest4: {
				title: "Гепатит - це жовтяниця?",
				answer: {
					desc: "Жовтяниця — це комплекс симптомів, при якому шкіра і слизові набувають жовтого відтінку. Це відбувається із-за підвищення рівня білірубіну в крові, яке зустрічається при різних захворюваннях, найчастіше при сильному запаленні тканини печінки, у тому числі при гострому і хронічному гепатиті. Тому коли говорять про жовтяниці, дуже часто автоматично передбачають гепатит. Але це не завжди так.",
					desc2: "Гепатит і жовтяниця не синоніми, це різні поняття.",
				},
			},
			quest5: {
				title: "Якими бувают віруси гепатиту?",
				answer: {
					desc: "До збудників гепатиту вчені відносять більше 10 вірусів.",
					desc2:
						"Основних — п’ять: A, B, C, D, E. Всі вони розмножуються в печінці, але гепатити, викликані різними вірусами, розрізняються симптомами, тривалістю і формами перебігу хвороби, способів передачі і здатністью заражати.",
					desc3:
						"Коли говорять про хронічних вірусних гепатитах, найчастіше мають на увазі гепатити В і С. Можна також зустріти абревіатуру: ВГС і HCV — вірус гепатиту С; ВГВ і HBV — вірус гепатиту В. Ми для стислості будемо писати «гепатит», маючи на увазі хронічні вірусні гепатити В і С.",
				},
			},
			quest6: {
				title: "Чим гострий гепатит відрізняється від хронічного?",
				answer: {
					desc: "Гострий і хронічний гепатит — це не різні захворювання, а послідовні фази одного.",
					desc2:
						"Гострий гепатит — фаза, коли організм активно бореться з інфекцією, намагаючись повністю видалити її з організму.",
					desc3:
						"Гострий гепатит виникає у всіх людей незабаром після інфікування і триває, як правило, не більше 6 місяців.",
					desc4:
						"Хронічний гепатит — постійне запалення печінки. Він настає, якщо протягом гострої форми організму не вдалося впоратися з вірусом. В хронічну форму переходять в основному тільки гепатити В і С. Саме з цим пов’язана їх висока небезпека для здоров’я людини.",
				},
			},
			quest7: {
				title: "Організм може самостійно впоратись з гепатитом?",
				answer: {
					desc: "Так. Але тільки з гострим гепатитом. Якщо гепатит перейшов у хронічну форму, одужати самостійно вже не вийде — обов’язково потрібна допомога лікаря.",
				},
			},
			quest8: {
				title: "Чому гепатит стає хронічним?",
				answer: {
					desc: "Це залежить від особливостей організму, сили імунітету і кількості вірусу, що потрапив в організм. Коли імунітет не може впоратися з інфекцією, гепатит стає хронічним, тобто постійним.",
				},
			},
			quest9: {
				title: "Що таке ко-інфекція?",
				answer: {
					desc: "Ко-інфекція — це коли людина хворіє відразу кількома захворюваннями. Наприклад, гепатит В і гепатит С, гепатит С та ВІЛ і т. д. Як правило, одночасне зараження декількома вірусними захворюваннями робить їх протягом більш важким і ускладнює лікування обох. Так, у людини, що хворіє на гепатит В, наприклад, в поєднанні з гепатитом с, вище ризик наслідків для печінки, ніж у випадку цих хвороб окремо.",
				},
			},
			quest10: {
				title: "Що таке генотип?",
				answer: {
					desc: "Віруси з одним і тим же назвою можуть трохи відрізнятися один від одного. З цим пов’язані відмінності у перебігу захворювання, поведінці вірусу, як він відповідає на ліки.",
					desc2:
						"Розрізняють 10 основних генотипів гепатиту В та 6 — генотипів вірусу гепатиту С.",
					desc3:
						"Генотип — важливий параметр, від якого залежить вибір схеми лікування.",
				},
			},
			quest11: {
				title: "Чи можу я заразитися гепатитом?",
				answer: {
					desc: "Так. Від зараження гепатитом ніхто не застрахований.",
					desc2:
						"Захворіти може будь-яка людина незалежно від соціального стану і доходів.",
					desc3:
						"Якщо людина щеплення від гепатиту В, ця хвороба йому не загрожує. Від гепатиту С щеплення немає.",
				},
			},
			quest12: {
				title: "Як передаються гепатити В і С?",
				answer: {
					desc: "Віруси гепатитів В і С містяться в крові та біологічних рідинах хворого людини. Зараження відбувається, коли його кров, слина, сперма або виділення з піхви потрапляє в кров здорової людини.",
				},
			},
			quest13: {
				title: "Як можна заразитися гепатитом?",
				answer: {
					desc: "Ризик зараження тим вище, чим більше інфекції потрапляє в організм. Тому найбільшу небезпеку представляє контакт із зараженою кров’ю, де міститься найбільше копій вірусу.",
					desc2:
						"Це може відбутися при переливанні неперевіреної крові або її продуктів, а також проведення маніпуляцій, пов’язаних із пошкодженням шкіри, нестерильними або погано обробленими інструментами.",
				},
			},
			quest14: {
				title:
					"Чи підпадають пацієнти з вірусними гепатитами призову до армії?",
				answer: {
					desc: "Згідно наказу НАКАЗу МО України № 402 від 14.08.2008 р.",
					textA:
						"А) Тяжкі форми вірусних гепатитів, які важко піддаються лікуванню та значно порушують функції органів і систем ",
					textStrongA:
						"Непридатні до військової служби з виключенням з військового обліку",
					textB: "Б) Вірусні гепатити з незначним порушенням функцій",
					textStrongB:
						"Непридатні до військової служби в мирний час, обмежено придатні у воєнний час",
					textC: "В) Вірусні гепатити без порушення функції",
					textStrongC:
						"Непридатні до військової служби в мирний час, обмежено придатні у воєнний час",
					desc2:
						"Рішення про придатність/непридатність приймає Комісія у військовому комісаріаті.",
				},
			},
		},
	},

	//*** SectionNews ***/ без переводу
	news: {
		title: "Новини",
		subtitle: "Останні новини",
		subtitle2: "Переглянути всі новини",
		btnBack: "Назад",
		allNews: {
			article22: {
				id: "22",
				url: "/images/news/22.png",
				date: "12 Липня 2022",
				author: "Автор ",
				authorName: ": Вольдемар",
				title:
					"Рекомендації з організації медичної допомоги дорослим та дітям із ВГС",
				text: "На Міжнародному конгресі із захворювань печінки EASL 2022 ВООЗ презентувала оновлені Рекомендації з організації медичної допомоги дорослим та дітям з ВГС.",
				textPage1:
					"Дані рекомендації значно спрощують доступ до тестування, догляду та лікування ВГС.",
				textPage2: "З основними оновленнями можна ознайомитись за посиланням.",
				descLink: "Оновлені рекомендації по ",
				link: "ВГС 07.2022.ВООЗ eng.pdf",
			},
			article21: {
				id: "21",
				url: "/images/news/21.png",
				date: "5 Травня 2022",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Проведення тренінгу для фандрайзингового розвитку організації",
				text: "Види послуг:",
				li1: "1. Тренінг із залучення ресурсів;",
				li2: "2. Консультації для розробки фандрейзингової стратегії ",
			},
			article20: {
				id: "20",
				url: "/images/news/20.png",
				date: "3 Лютого 2022",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Вакцина від гепатиту С",
				text: "Незважаючи на вдосконалені методи лікування, зараження вірусом гепатиту С все ще призводить до приблизно 400 000 смертей у всьому світі щороку.",
			},
			article19: {
				id: "19",
				url: "/images/news/19.png",
				date: "27 Січня 2022",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Фінансовий звіт 2021",
				text: "Вітаємо волонтерів, друзів, колег, клієнтів та партнерів.",
				text2:
					"Ми щиро вдячні кожному, хто підтримує діяльність ГО «BCD» по елімінації епідемії вірусних гепатитів розділяючи нашу Місію",
			},
			article18: {
				id: "18",
				url: "/images/news/18.png",
				date: "12 Серпня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Гепатити не чекають",
				text: "Завантажуйте за посиланням брошуру “Процес консультування та маршрут діагностики, лікування, моніторингу пацієнтів із вірусними гепатитами B, С (моноінфекцією та з ко-інфекцією ВІЛ, ТБ)”, розроблену Громадською організацією “bcd” за підтримки Центру громадського здоров’я України.",
			},
			article17: {
				id: "17",
				url: "/images/news/17.png",
				date: "15 Липня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Програма здоров’я населення Немішаївської ОТГ",
				text: "14 липня 2021 року відбулося чергове засідання робочої групи з розроблення Програми здоров’я населення Немішаївської територіальної громади.",
				text2:
					"В рамках засідання ефективно попрацювали над пропозиціями, зауваженнями по наповненню",
			},
			article16: {
				id: "16",
				url: "/images/news/16.png",
				date: "23 Червня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Новій громаді – нову програму здоров’я Немішаївській ОТГ",
				text: "10 червня 2021 року відбулося обговорення Програми розвитку громадського здоров’я у Немішаївській ОТГ. Подія відбулась в рамках першого засідання робочої групи утвореної розпорядженням №20 від 13.05.2021р.",
			},

			article15: {
				id: "15",
				url: "/images/news/15.png",
				date: "6 Травня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Програма громадського здоров’я",
				text: "Вчора 6 травня 2021 року відбулася надважлива зустріч представників громадської організації «BCD» з Перевозником Петром Петровичем – селищним головою Немішаївської ОТГ та Когутом Іваном Івановичем – заступником селищного голови з питань громадського здоров’я з метою обговорення розвитку громадського здоров’я",
			},
			article14: {
				id: "14",
				url: "/images/news/14.png",
				date: "22 Квітня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Фінансовий звіт 2020",
				text: "Вітаємо, друзі.",
				text2:
					"Ми щиро вдячні кожному, хто підтримує діяльність ГО «BCD» та розділяє наші Місії:",
				text3:
					"Ми об′єднані досвідом та метою заради здорового майбутнього для кожного, без вірусних гепатитів.",
				text4: "Ми працюємо з людьми і для людей.",
			},
			article13: {
				id: "13",
				url: "/images/news/13.png",
				date: "2 Квітня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Гепатит – доступні аналізи",
				text: "Маєш позитивний результат швидкого тесту на гепатит B чи С?",
				text2: "В лікарні поставили діагноз хронічний гепатит?",
				text3: "Плануєш лікування гепатиту та не знаєш, які потрібні аналізи?",
				text4: "Необхідна діагностика вірусних гепатитів?",
			},
			article12: {
				id: "12",
				url: "/images/news/12.png",
				date: "5 Березня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Річний звіт Громадьскої організацїї БСД",
				text: "Вітаємо, Друзі.",
				text2:
					"Команда БСД стрімко росте і набирає обертів, ми реалізуємо та вже реалізували багато проєктів, щоб допомогти Україні отримати перемогу над ВГ",
			},
			article11: {
				id: "11",
				url: "/images/news/11.png",
				date: "22 Лютого 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title:
					"Вебінар про роль сімейних лікарів в діагностиці та лікуванні вірусного гепатиту",
				text: "Запрошуємо взяти участь у вебінарі про роль сімейних лікарів в діагностиці та лікуванні вірусного гепатиту C.",
				text2: "Початок 23 лютого 14.00-16.00",
			},
			article10: {
				id: "10",
				url: "/images/news/10.png",
				date: "19 Січня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Лікування гепатитів В та С стане доступнішим",
				text: "Прийнято нові Стандарти надання медичної допомоги дорослим та дітям із вірусними гепатитами В та С. Вони дозволять значно розширити доступ пацієнтів до лікування цих інфекцій.",
			},
			article9: {
				id: "9",
				url: "/images/news/9.png",
				date: "19 Січня 2021",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Оголошується тендер",
				text: "Громадська організація «БСД» – це спільнота людей, яких об’єднує успішний досвід у вирішенні проблеми, пов’язаної з вірусними гепатитами. Ми об’єднуємося, щоб подолати епідемію вірусних гепатитів в Україні",
			},
			article8: {
				id: "8",
				url: "/images/news/8.png",
				date: "17 Грудня 2020",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Епідемія вірусних гепатитів В і С",
				text: "В Україні 2 000 000 людей, що повільно помирають від цих захворювань та потребують фахової допомоги. Команда пацієнтської спільноти ГО БСД в рамках Проєкту Continuous Patient Education, European AIDS Treatment Group закінчує роботу над брошурою.",
			},
			article7: {
				id: "7",
				url: "/images/news/7.png",
				date: "10 Грудня 2020",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Меморандум про співпрацю між ЦГЗ МОЗ України та ГО БСД",
				text: "9 грудня 2020 року в.о. директора ЦГЗ МОЗ України Ігор Кузін та виконавчий директор ГО БСД Юрій Войналович, що представляє спільноту людей з вірусними гепатитами, підписали Меморандум про співпрацю.",
			},
			article6: {
				id: "6",
				url: "/images/news/6.png",
				date: "21 Лютого 2020",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Велика перемога маленьких пацієнтів (історія Юлі та Михайла)",
				text: "Малозабезпечена сім’я з трьома дітками звернулися за допомогою до церкви Скінія в Сєверодонецьку. Невеликому місті в прифронтовій зоні Луганської області.",
			},
			article5: {
				id: "5",
				url: "/images/news/5.png",
				date: "9 Січня 2020",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Старт Проєкту «Обізнана молодь-здорова громада»",
				text: "01 січня 2020 році громадська організація “БСД”, завдяки спільному фінансуванню з Бюджету участі селища Немішаєве починає реалізувати Проєкт: «Обізнана молодь-здорова громада»",
			},
			article4: {
				id: "4",
				url: "/images/news/4.png",
				date: "19 Листопада 2019",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "“Обізнана молодь-здорова громада”",
				text: "Сьогодні настав час змін на краще.",
				text2:
					"Кожен свідомий та активний член громади може зробити посильний внесок в покращення якості життя містечка Немішаєве",
			},
			article3: {
				id: "3",
				url: "/images/news/3.png",
				date: "13 Жовтня 2019",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Шляхи передачі гепатиту",
				text: "Коли ви вперше дізнаєтеся про те, що хворі на гепатит, одні з перших питань, які виникають у вас, – як і де я міг (могла) заразитися? Як мені тепер жити, вести себе зі своїми близькими, щоб не заразити їх?",
			},
			article2: {
				id: "2",
				url: "/images/news/2.png",
				date: "13 Жовтня 2019",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Ураження печінки",
				text: "Пошкодження печінки і пов’язана з цим неможливість печінки виконувати свої функціі – головна небезпека хронічних вірусних гепатитів.",
				text2: "В процесі захворювання печінка проходить через кілька стадій.",
			},
			article1: {
				id: "1",
				url: "/images/news/1.png",
				date: "13 Жовтня 2019",
				author: "Автор ",
				authorName: ": Вольдемар",
				title: "Ураження печінки",
				text: "Гепатит – це запалення печінки, внаслідок якого руйнуються клітини печінки. Його причиною можуть стати інфекції, зловживання алкоголем, жирною їжею, отруєння, в тому числі лікарське, неправильний способу життя.",
			},
		},
	},

	//*** SectionContacts ***/ без переводу
	contacts: {
		title: "Де ми знаходимось?",
		address: "Адреса",
		address2: "07853, вулиця Технікумівська, 20/20",
		address3: "смт Немішаєве, Київської області",
		phone: "Номер телефону",
		phone2: "+38 (073) 044-73-44",
		phone3: "Viber/Telegram",
		post: "Адреса для поштових відправлень",
		post2: "04128 Київ, а/с 28 ",
	},
};
