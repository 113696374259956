import style from "./question.module.scss";
import { Button } from "../../../Button";
import { Close } from "../../../Icons";
import { Answer } from "../Answer";
import { useState, useRef, useEffect } from "react";

export function Question({ question, answers }) {
	const [isOpenAnswer, setIsOpenAnsver] = useState();
	const btnRef = useRef(null);
	const handleTogleAnswer = () => {
		setIsOpenAnsver(!isOpenAnswer);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				isOpenAnswer &&
				btnRef.current &&
				!btnRef.current.contains(event.target)
			) {
				setIsOpenAnsver(false); 
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isOpenAnswer]);

	return (
		<div className={style.wrapper_question}>
			<Button
				btnType={"button"}
				classBtn={style.help_btnContainer}
				btnRef={btnRef}
				btnClick={handleTogleAnswer}
			>
				<span className={style.help_question}>{question}</span>
				<span className={`${style.help_icon} ${isOpenAnswer&& style.active}`}>
					<Close
						width={15}
						height={15}
						stroke="#28609D"
					/>
				</span>
			</Button>
			<div className={style.help_wrapper}>
				<Answer
					answer={answers}
					isOpenAnswer={isOpenAnswer}
				/>
			</div>
		</div>
	);
}
