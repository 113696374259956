export function Close({ width = 18, height = 18, stroke="#CE5349"}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 1L17 17"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M1 17L17 1"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}
