import style from "./sliderNews.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import { Article } from "../Article";
import { ArrowSwiper } from "../../../Icons";

export function SliderNews({ newsData, isMobile }) {
	return (
		<Swiper
			modules={[Autoplay, FreeMode, Navigation]}
			loop={true}
			// slidesPerGroup={1}
			slidesPerView={isMobile ? 1 : 3}
			spaceBetween={90}
			speed={500}
			navigation={{
				prevEl: `.${style.prevBtn}`,
				nextEl: `.${style.nextBtn}`,
			}}
		>
			<div
				slot="container-end"
				className={style.btn_container}
			>
				<div className={style.prevBtn}>
					<ArrowSwiper />
				</div>
				<div className={style.nextBtn}>
					<ArrowSwiper />
				</div>
			</div>
			{newsData.map((item, index) => (
				<SwiperSlide
					key={index}
					className={style.swiperSlide}
				>
					<Article
						key={index}
						news={item[Object.keys(item)[0]]}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
