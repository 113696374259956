import { useTranslation } from "react-i18next";
import style from "./sectionQuestions.module.scss";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { Question } from "./componentsSectionQuestions/Question";

export function SectionQuestions() {
	const { t } = useTranslation();
	const questionsData = useNestedObjectTranslation(
		uk,
		"questions",
		"allQuestions"
	);

	return (
		<section className={style.wrapperQuestions}>
			<div className={style.container}>
				<div className={style.box_title}>
					<h3 className={style.title}>{t("questions.title")}</h3>
					<h4 className={style.subtitle}>{t("questions.subtitle")}</h4>
				</div>
				<div className={style.content_wrapper}>
					<div className={style.box_questions}>
						{questionsData.map((ques, index) =>
							Object.keys(ques).map((key) => {
								const question = ques[key];
								return (
									question && (
										<Question
											key={index}
											question={question.title}
											answers={question.answer}
										/>
									)
								);
							})
						)}
					</div>
					<div className={style.box_picture}>
						<div className={style.image}>
							<img
								src="/images/question/faq.svg"
								alt="FAQ"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
