export function Telegram({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="#40B0EF"
			/>
			<path
				d="M27.9029 13.4648C27.8669 13.9153 27.7334 14.3928 27.6319 14.8748C26.7884 18.8693 25.9404 22.8623 25.0924 26.8558C25.0434 27.0853 24.991 27.3153 24.868 27.5213C24.6655 27.8588 24.3724 27.9828 23.9889 27.8953C23.7474 27.8403 23.5374 27.7268 23.3389 27.5798C22.0604 26.6313 20.7769 25.6893 19.5024 24.7353C19.3404 24.6138 19.2474 24.6273 19.1104 24.7633C18.4819 25.3878 17.841 25.9998 17.2105 26.6228C16.97 26.8603 16.7039 27.0028 16.3539 26.9903C16.1064 26.9813 15.965 26.8803 15.8895 26.6448C15.4955 25.4133 15.0945 24.1843 14.6955 22.9543C14.56 22.5368 14.417 22.1218 14.2905 21.7018C14.245 21.5503 14.1669 21.4733 14.0139 21.4263C12.7629 21.0423 11.5149 20.6488 10.2659 20.2588C10.1009 20.2073 9.93594 20.1553 9.78894 20.0628C9.43244 19.8388 9.40394 19.5388 9.71395 19.2553C9.92595 19.0613 10.1839 18.9458 10.4484 18.8438C12.1519 18.1858 13.8554 17.5283 15.5589 16.8713C19.2369 15.4533 22.9154 14.0353 26.5934 12.6178C26.6229 12.6063 26.6514 12.5938 26.6809 12.5828C27.3709 12.3243 27.8979 12.6858 27.9029 13.4648ZM16.3619 25.8163C16.4289 25.7848 16.423 25.7393 16.426 25.7038C16.51 24.8063 16.5969 23.9093 16.6704 23.0113C16.6889 22.7878 16.7579 22.6218 16.9314 22.4663C19.3289 20.3133 21.7199 18.1533 24.1124 15.9943C24.2639 15.8578 24.4164 15.7228 24.5644 15.5823C24.6254 15.5243 24.7055 15.4578 24.6585 15.3618C24.609 15.2618 24.503 15.2658 24.409 15.2768C24.225 15.2978 24.0685 15.3878 23.915 15.4848C20.9645 17.3463 18.0149 19.2088 15.0609 21.0648C14.8929 21.1703 14.8699 21.2668 14.9299 21.4463C15.2954 22.5383 15.6494 23.6343 16.0074 24.7293C16.1254 25.0903 16.2429 25.4518 16.3619 25.8163Z"
				fill="#FEFEFE"
			/>
		</svg>
	);
}
