export function DoubleQuotes({ width = 78, height = 78 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 78 78"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 0H36.1012V39.7861L0.669681 77.9974V64.5986L19.1841 38.8764H0V0Z"
				fill="#03071E"
			/>
			<path
				d="M41.8984 0H77.9996V39.7861L42.5681 78V64.6012L61.0825 38.879H41.8984V0Z"
				fill="#03071E"
			/>
		</svg>
	);
}
