import { SectionAdvantages } from "../../components/SectionAdvantages";
import { SectionHepatit } from "../../components/SectionHepatit";
import { useMediaQuery } from "react-responsive";
import { SectionOrganization } from "../../components/SectionOrganization";
import { SectionTeam } from "../../components/SectionTeam";
import { SectionStories } from "../../components/SectionStories";
import { SectionSupport } from "../../components/SectionSupport";
import { SectionPartners } from "../../components/SectionPartners/insex";
import { SectionQuestions } from "../../components/SectionQuestions";
import { SectionNews } from "../../components/SectionNews";
import { SectionContacts } from "../../components/SectionContacts";

export function Home() {
	const isMobile = useMediaQuery({ maxWidth: 991 });

	return (
		<div id="main">
			<SectionHepatit isMobile={isMobile} />
			<SectionAdvantages />
			<SectionOrganization />
			<SectionTeam isMobile={isMobile} />
			<SectionStories />
			<SectionSupport />
			<SectionPartners isMobile={isMobile} />
			<SectionQuestions />
			<SectionNews isMobile={isMobile} />
			<SectionContacts />
		</div>
	);
}
