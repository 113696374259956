export const supportImg = [
	"/images/support/1.png",
	"/images/support/2.png",
	"/images/support/3.png",
	"/images/support/4.png",
	"/images/support/5.png",
];

export const partnersImg = [
	"/images/partners/1.png",
	"/images/partners/2.png",
	"/images/partners/3.png",
	"/images/partners/4.png",
	"/images/partners/5.png",
	"/images/partners/6.png",
	"/images/partners/7.png",
	"/images/partners/8.png",
	"/images/partners/9.png",
	"/images/partners/10.png",
	"/images/partners/11.png",
	"/images/partners/12.png",
	"/images/partners/13.png",
	"/images/partners/14.png",
	"/images/partners/15.png",
	"/images/partners/16.png",
	"/images/partners/17.png",
	"/images/partners/18.png",
	"/images/partners/19.png",
	"/images/partners/20.png",
	"/images/partners/21.png",
];