export function Whatsapp({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="#35CC50"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.1102 7.38086C13.1423 7.38086 7.47363 12.9804 7.47363 19.8633C7.47363 22.2848 8.16943 24.6101 9.4919 26.6282L7.5724 30.8052C7.39561 31.1901 7.4598 31.6413 7.73782 31.9633C7.95066 32.2096 8.25931 32.3462 8.57734 32.3462C8.67462 32.3462 8.77289 32.3335 8.86918 32.3072L14.1586 30.8735C15.9847 31.8379 18.0321 32.3457 20.1107 32.3457C27.0786 32.3457 32.7477 26.7462 32.7477 19.8628C32.7467 12.9804 27.0781 7.38086 20.1102 7.38086ZM20.1102 30.1657C18.2647 30.1657 16.4484 29.6809 14.8588 28.7628C14.6884 28.6643 14.4958 28.614 14.3023 28.614C14.2045 28.614 14.1062 28.6267 14.0104 28.653L10.5551 29.5896L11.7586 26.9706C11.922 26.6145 11.8801 26.1994 11.6484 25.8823C10.361 24.1199 9.68054 22.0384 9.68054 19.8633C9.68054 14.1823 14.3591 9.56086 20.1102 9.56086C25.8613 9.56086 30.5398 14.1823 30.5398 19.8633C30.5398 25.5443 25.8613 30.1657 20.1102 30.1657Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.968 21.7328C24.4386 21.4201 23.7502 21.0708 23.1265 21.323C22.6485 21.5162 22.3428 22.2562 22.0332 22.6337C21.8742 22.8274 21.6841 22.8576 21.4401 22.7606C19.6445 22.0542 18.2687 20.8703 17.2781 19.2386C17.1102 18.985 17.1404 18.785 17.3428 18.5503C17.6421 18.2025 18.0179 17.8069 18.0989 17.3376C18.1799 16.8684 17.9571 16.3201 17.7606 15.9025C17.5097 15.3689 17.2292 14.6074 16.688 14.3054C16.1902 14.0279 15.5349 14.183 15.0915 14.5396C14.3265 15.1552 13.9571 16.1196 13.968 17.0752C13.971 17.3469 14.005 17.6176 14.0692 17.8796C14.2238 18.5103 14.5186 19.0991 14.8505 19.6586C15.1008 20.0806 15.3729 20.4903 15.6663 20.884C16.6268 22.1728 17.8213 23.2928 19.2046 24.1294C19.8959 24.5474 20.6401 24.9142 21.4115 25.1659C22.2762 25.4479 23.0465 25.742 23.9799 25.5669C24.9576 25.3835 25.9211 24.7859 26.3092 23.8552C26.4238 23.5796 26.4816 23.2728 26.4174 22.982C26.285 22.3815 25.4608 22.024 24.968 21.7328Z"
				fill="white"
			/>
		</svg>
	);
}
