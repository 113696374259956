export function Messenger({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="#0070E0"
			/>
			<path
				d="M20.2825 9C14.3275 9 9.5 13.498 9.5 19.046C9.5 22.2075 11.068 25.028 13.5185 26.8695V30.7L17.19 28.6725C18.17 28.9455 19.208 29.093 20.283 29.093C26.2385 29.093 31.066 24.595 31.066 19.0465C31.0655 13.498 26.238 9 20.2825 9ZM21.3545 22.529L18.608 19.582L13.25 22.529L19.144 16.233L21.957 19.18L27.248 16.233L21.3545 22.529Z"
				fill="white"
			/>
		</svg>
	);
}
