export function Instagram({ width = 40, height = 40 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="40"
				height="40"
				rx="20"
				fill="url(#paint0_linear_282_4463)"
			/>
			<path
				d="M27.1517 14.6277C28.0122 14.6277 28.7098 13.9302 28.7098 13.0697C28.7098 12.2093 28.0122 11.5117 27.1517 11.5117C26.2913 11.5117 25.5938 12.2093 25.5938 13.0697C25.5938 13.9302 26.2913 14.6277 27.1517 14.6277Z"
				fill="white"
			/>
			<path
				d="M20.2261 13.5088C16.6176 13.5088 13.6816 16.4448 13.6816 20.0533C13.6816 23.6618 16.6176 26.5978 20.2261 26.5978C23.8346 26.5978 26.7701 23.6618 26.7701 20.0533C26.7701 16.4448 23.8346 13.5088 20.2261 13.5088ZM20.2261 24.2453C17.9146 24.2453 16.0341 22.3648 16.0341 20.0533C16.0341 17.7418 17.9146 15.8613 20.2261 15.8613C22.5376 15.8613 24.4181 17.7418 24.4181 20.0533C24.4181 22.3648 22.5376 24.2453 20.2261 24.2453Z"
				fill="white"
			/>
			<path
				d="M25.4216 33.3406H14.8121C10.4106 33.3406 6.83008 29.7601 6.83008 25.3586V14.7486C6.83008 10.3471 10.4106 6.7666 14.8121 6.7666H25.4216C29.8231 6.7666 33.4041 10.3471 33.4041 14.7486V25.3586C33.4041 29.7601 29.8231 33.3406 25.4216 33.3406ZM14.8121 9.2666C11.7891 9.2666 9.33008 11.7256 9.33008 14.7486V25.3586C9.33008 28.3816 11.7891 30.8406 14.8121 30.8406H25.4216C28.4446 30.8406 30.9041 28.3816 30.9041 25.3586V14.7486C30.9041 11.7256 28.4446 9.2666 25.4216 9.2666H14.8121Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_282_4463"
					x1="33"
					y1="3.75"
					x2="8.5"
					y2="33.75"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A139BF" />
					<stop
						offset="0.520833"
						stopColor="#EA4780"
					/>
					<stop
						offset="1"
						stopColor="#FCCC65"
					/>
				</linearGradient>
			</defs>
		</svg>
	);
}
