import style from "./ownStory.module.scss";
import { SocialLink } from "../../../SocialLink";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function OwnStory({ story }) {
	const { t } = useTranslation();

	return (
		<div className={style.own_body}>
			<h3 className={style.title}>{story.title}</h3>
			<div className={style.own_content}>
				<div className={style.title_box}>
					<div className={style.title_info}>
						<div className={style.title_date}>{story.date}</div>
						<div className={style.author}>
							<span>{story.author}</span>
							<span>{story.nameAuthor}</span>
						</div>
					</div>
					<div className={style.title_image}>
						<img
							src={story.url1}
							alt={story.title}
						/>
					</div>
				</div>
				<p className={style.content_text1}>{story.text1}</p>
				<p className={style.content_text2}>{story.text2}</p>
				<p className={style.content_text3}>{story.text3}</p>
				<p className={style.content_text4}>{story.text4}</p>
				<div className={style.second_box}>
					<div className={style.second_image}>
						<img
							src={story.url2}
							alt={story.title}
						/>
					</div>
					<div className={style.desc_img}>{story.descUrl2}</div>
				</div>
				<p className={style.content_text5}>{story.text5}</p>
				<p className={style.content_text6}>{story.text6}</p>
				<div className={style.title_image3}>{story.titleUrl3}</div>
				<div className={style.third_box}>
					<div className={style.third_image}>
						<img
							src={story.url3}
							alt={story.title}
						/>
					</div>
					<div className={style.desc_img3}>{story.descUrl3}</div>
				</div>
			</div>
			<div className={style.own_social}>
				<SocialLink />
				<Link
					to={"/stories"}
					className={style.btn_back}
				>
					<span>{t("stories.btnBack")}</span>
				</Link>
			</div>
		</div>
	);
}
