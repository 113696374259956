import style from "./pageArticle.module.scss"
import { Link } from "react-router-dom";
import { SocialLink } from "../../../SocialLink";
import { useTranslation } from "react-i18next";

export function PageArticle({ article }) {
	const { t } = useTranslation();

	return (
		<div className={style.wrapper}>
			<div className={style.content_box}>
				<h3 className={style.title}>{article.title}</h3>
				<div className={style.title_info}>
					<div className={style.title_date}>{article.date}</div>
					<div className={style.author}>
						<span>{article.author}</span>
						<span>{article.authorName}</span>
					</div>
				</div>
				<div className={style.image}>
					<img
						src={article.url}
						alt={article.title}
					/>
				</div>
				<div className={style.desc_body}>
					<p className={style.text}>{article.text}</p>
					{article.li1 && (
						<ul className={style.list}>
							<li className={style.item}>{article.li1}</li>
							<li className={style.item}>{article.li2}</li>
						</ul>
					)}
					{article.text2 && <p className={style.text}>{article.text2}</p>}
					{article.text3 && <p className={style.text}>{article.text3}</p>}
					{article.text4 && <p className={style.text}>{article.text4}</p>}
					{article.textPage1 && (
						<p className={style.text}>{article.textPage1}</p>
					)}
					{article.textPage2 && (
						<p className={style.text}>{article.textPage2}</p>
					)}
					{article.descLink && article.link && (
						<div className={style.link_box}>
							<span>{article.descLink}</span>
							<a href="##">{article.link}</a>
						</div>
					)}
				</div>
			</div>
			<div className={style.social}>
				<SocialLink />
				<Link
					to={"/news"}
					className={style.btn_back}
				>
					<span>{t("news.btnBack")}</span>
				</Link>
			</div>
		</div>
	);
}