import { useTranslation } from "react-i18next";
import style from "./sectionOrganization.module.scss";
import { Button } from "../Button";

export function SectionOrganization() {
	const { t } = useTranslation();

	return (
		<section className={style.wrapperOrganization}>
			<div className={style.container}>
				<div className={style.image}>
					<img
						src="/images/organization/be_free_hepc.png"
						alt="BeFreeHepC"
					/>
				</div>
				<div className={style.box_content}>
					<h3 className={style.title}>{t("organization.title")}</h3>
					<h4 className={style.subtitle}>{t("organization.subtitle")}</h4>
					<p className={style.desc}>{t("organization.desc1")}<br/>{t("organization.desc2")}</p>
					<Button
						classBtn={style.btn_org}
						btnType={"button"}
						text={t("organization.btn")}
					/>
				</div>
			</div>
		</section>
	);
}
