import { Link } from "react-router-dom";
import style from "./newsDetails.module.scss";

export function NewsDetails({ article, isMobile }) {
	return (
		<Link
			to={`/news/${article.id}`}
			className={style.link_details}
		>
			<div className={style.box_details}>
				<div className={style.content_box}>
					{isMobile && (
						<div className={style.title_boxMob}>
							<div className={style.dateMob}>{article.date}</div>
							<div className={style.authorMob}>
								<span>{article.author}</span>
								<span>{article.authorName}</span>
							</div>
						</div>
					)}
					<h3 className={style.title}>{article.title}</h3>
					<div className={style.desc_body}>
						<p className={style.text}>{article.text}</p>
						{article.li1 && (
							<ul className={style.list}>
								<li className={style.item}>{article.li1}</li>
								<li className={style.item}>{article.li2}</li>
							</ul>
						)}
						{article.text2 && <p className={style.text}>{article.text2}</p>}
						{article.text3 && <p className={style.text}>{article.text3}</p>}
						{article.text4 && <p className={style.text}>{article.text4}</p>}
					</div>
					{!isMobile && <div className={style.date}>{article.date}</div>}
				</div>
				<div className={style.image}>
					<img
						src={article.url}
						alt={article.title}
					/>
				</div>
			</div>
		</Link>
	);
}
