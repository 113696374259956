import style from "./answer.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { boxAnswerAnimation } from "../../../../utils/animation";

export function Answer({ answer, isOpenAnswer }) {
	return (
		<AnimatePresence>
			{answer && isOpenAnswer && (
				<motion.div
					className={style.box_answers}
					{...boxAnswerAnimation}
				>
					<p className={style.text}>{answer.desc}</p>
					{answer.textA && (
						<>
							<p className={style.text}>{answer.textA}</p>
							<p className={style.text_strong}>{answer.textStrongA}</p>
							<p className={style.text}>{answer.textB}</p>
							<p className={style.text_strong}>{answer.textStrongB}</p>
							<p className={style.text}>{answer.textC}</p>
							<p className={style.text_strong}>{answer.textStrongC}</p>
						</>
					)}
					{answer.desc2 && <p className={style.text}>{answer.desc2}</p>}
					{answer.li1 && (
						<ul className={style.list}>
							<li className={style.item}>{answer.li1}</li>
							<li className={style.item}>{answer.li2}</li>
							<li className={style.item}>{answer.li3}</li>
							<li className={style.item}>{answer.li4}</li>
						</ul>
					)}
					{answer.desc3 && <p className={style.text}>{answer.desc3}</p>}
					{answer.desc4 && <p className={style.text}>{answer.desc4}</p>}
				</motion.div>
			)}
		</AnimatePresence>
	);
}
