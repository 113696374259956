import { useTranslation } from "react-i18next";
import style from "./stories.module.scss";
import { uk } from "../../i18n/locales/uk";
import { useNestedObjectTranslation } from "../../utils/hooks/useNestedObjectTranslation";
import { useMediaQuery } from "react-responsive";
import { PagePerson } from "../../components/SectionStories/componentsSectionStories/PagePerson";

export function Stories() {
	const { t } = useTranslation();
	const storiesData = useNestedObjectTranslation(uk, "stories", "people");
	const isMobile = useMediaQuery({ maxWidth: 991 });

	return (
		<div id="main">
			<section className={style.wrapperStories}>
				<div className={style.container}>
					<h2 className={style.title}>{t("stories.title")}</h2>
					<div className={style.stories_box}>
						{storiesData.map((item, index) => {
							return (
								<PagePerson
									key={index}
									isMobile={isMobile}
									person={item[Object.keys(item)[0]]}
								/>
							);
						})}
					</div>
				</div>
			</section>
		</div>
	);
}
