import { Close } from "../../../Icons";
import style from "./member.module.scss";
import { Button } from "../../../Button";

export function Member({
	isMobile,
	member,
	onOpen,
	onClose,
	isModal,
	selectedMember,
}) {
	return (
		<div
			className={`${style.member_body} ${
				!selectedMember && !isMobile && style.active
			} ${selectedMember && isModal && style.size}`}
		>
			<div
				className={style.image}
				onClick={onOpen}
			>
				<img
					src={member?.url}
					alt="Foto"
				/>
			</div>
			{(selectedMember || isMobile) && (
				<div className={style.member_content}>
					{selectedMember && (
						<Button
							btnType={"button"}
							classBtn={style.member_btn}
							text={
								<Close
									width={18}
									height={18}
								/>
							}
							btnClick={onClose}
						/>
					)}
					<h4 className={style.member_name}>{member.name}</h4>
					<div className={style.member_position}>{member.position}</div>
					<p className={style.member_desc}>{member.desc}</p>
				</div>
			)}
			{!isMobile && !isModal && (
				<div
					className={style.info_box}
					onClick={onOpen}
				>
					<h3 className={style.info_name}>{member.name}</h3>
					<h4 className={style.info_position}>{member.position}</h4>
				</div>
			)}
		</div>
	);
}
