import { useTranslation } from "react-i18next";
import style from "./supportInfo.module.scss";

export function SupportInfo({onOpen}) {
	const {t} = useTranslation();

	return (
		<>
			<div className={style.box_content}>
				<h3 className={style.title} onClick={onOpen}>{t("support.title")}</h3>
				<h4 className={style.subtitle}>{t("support.subtitle")}</h4>
				<p className={style.desc}>{t("support.desc")}</p>
			</div>
			<div className={style.box_props}>
				<h3 className={style.title_props}>{t("support.title2")}</h3>
				<ul className={style.props_list}>
					<li className={style.props_item}>
						<span>{t("support.company")}</span>
						<span>{t("support.info1")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.current")}</span>
						<span>{t("support.info2")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.code")}</span>
						<span>{t("support.info3")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.service")}</span>
						<span>{t("support.info4")}</span>
					</li>
				</ul>
			</div>
		</>
	);
}