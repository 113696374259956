import { useTranslation } from 'react-i18next';
import style from './support.module.scss';
import { Button } from '../Button';
import { Close } from '../Icons';

export function Support({onClose}) {
	const {t} = useTranslation();

	return (
		<div className={style.wrapperSupport}>
			<div className={style.box_props}>
				<div className={style.props_header}>
					<h3 className={style.title_props}>{t("support.title2")}</h3>
					<Button
						btnType={"button"}
						classBtn={style.btn_support}
						text={
							<Close
								width={18}
								height={18}
								stroke="#FEFEFE"
							/>
						}
						btnClick={onClose}
					/>
				</div>
				<ul className={style.props_list}>
					<li className={style.props_item}>
						<span>{t("support.company")}</span>
						<span>{t("support.info1")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.current")}</span>
						<span>{t("support.info2")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.code")}</span>
						<span>{t("support.info3")}</span>
					</li>
					<li className={style.props_item}>
						<span>{t("support.service")}</span>
						<span>{t("support.info4")}</span>
					</li>
				</ul>
			</div>
		</div>
	);
}