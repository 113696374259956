export function DEUFlag  ({ width = 20, height = 20 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_212_2028"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="21"
				height="20"
			>
				<path
					d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_212_2028)">
				<path
					d="M27.1673 13.333H-6.16602V19.9997H27.1673V13.333Z"
					fill="#FFCC00"
				/>
				<path
					d="M27.1673 6.66699H-6.16602V13.3337H27.1673V6.66699Z"
					fill="#FF0000"
				/>
				<path
					d="M27.1673 0H-6.16602V6.6667H27.1673V0Z"
					fill="black"
				/>
			</g>
		</svg>
	);
}